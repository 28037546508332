import Vue from 'vue';
import Vuex from 'vuex';
import VueResource from 'vue-resource';

Vue.use(VueResource);
Vue.use(Vuex);

const state = {
  currentPanel: "order",
  expandLevel: 3,
  showPromptModal: false,
  showDriverModal: false,
  driverModalAmount: 0,
  modalType: 'NONE',
  editOrderScreenReadOnly: true,
  settingsPanelTab: 1,
  settingsPanelBillingTabComponent: null,
  lockTargetPanel: null,
  isLocked: false,
}

// getters
const getters = {
  getCurrentPanel: state => state.isLocked ? state.lockTargetPanel : state.currentPanel,
  getIsLocked: state => state.isLocked,
  getSettingsPanelTab: state => state.isLocked ? 1 : state.settingsPanelTab,
  getSettingsPanelBillingTabComponent: state => state.settingsPanelBillingTabComponent,
  getExpandLevel: state => state.expandLevel,
  getEditOrderScreenReadOnly: state => state.editOrderScreenReadOnly
}

// actions
const actions = {
  lockOnPanel({ commit }, targetPanel) {
    commit('setLock', { lockStatus: true, targetPanel });
  },
  unlockFromPanel({ commit }) {
    commit('setLock', { lockStatus: false, targetPanel: null });
  },
  setPanel({ commit }, panel) {
    commit('setPanel', panel);
  },
  setSettingsPanelTab({ commit }, tab) {
    commit('setSettingsPanelTab', tab);
  },
  setSettingsPanelBillingTabComponent({ commit }, component) {
    commit('setSettingsPanelBillingTabComponent', component);
  },
  toggleExpand(
    { commit },
    tabletMode
  ) {
    commit('toggleExpand', tabletMode);
  },
  expandLevelToDefault({commit}){
    commit('expandLevelToDefault')
  },
  changeExpandLevel({commit}, level){
    commit('changeExpandLevel', level)
  },
  togglePromptModal({
    commit
  }, modal_type) {
    commit('togglePromptModal', modal_type)
  },
  toggleDriverModal({
    commit
  }, amount) {
    commit('toggleDriverModal', amount)
  },
  editOrderScreenReadOnly({
    commit
  }, isReadOnly) {
    commit('editOrderScreenReadOnly', isReadOnly);
  },
}

// mutations
const mutations = {
  setLock(state, { lockStatus, targetPanel }) {
    state.isLocked = lockStatus;
    state.lockTargetPanel = targetPanel;
  },
  setPanel(state, panel) {
    state.currentPanel = panel;
  },
  setSettingsPanelTab(state, tab){
    state.settingsPanelTab = tab
  },
  setSettingsPanelBillingTabComponent(state, component){
    state.settingsPanelBillingTabComponent = component;
  },
  expandLevelToDefault(state){
    state.expandLevel = 3;
  },
  changeExpandLevel(state, level){
    state.expandLevel = level
  },
  toggleExpand(state, tabletMode) {
    if(tabletMode){
      if (state.expandLevel == 3) {
        state.expandLevel = 1;
      } else {
        state.expandLevel = 3;
      }
    }
    else{
      if (state.expandLevel == 3) {
        state.expandLevel = 2;
      } else {
        state.expandLevel = 3;
      }
    }
  },
  togglePromptModal(state, modal_type) {
    state.showPromptModal = !state.showPromptModal
    state.modalType = modal_type
  },
  toggleDriverModal(state, amount) {
    state.showDriverModal = !state.showDriverModal
    state.driverModalAmount = amount
  },
  editOrderScreenReadOnly(state, isReadOnly){
    state.editOrderScreenReadOnly = isReadOnly
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}