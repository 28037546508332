<template>
<div class="order-detail-page">
    <!-- Delivered Orders -->
    <div class="columns table-headings is-size-7">
        <div class="column is-1 name-column has-text-left move-text-up">NAME</div>
        <div class="column is-1 order-number-column has-text-left move-text-up">SOURCE</div>
        <div class="column is-1 order-number-column has-text-left move-text-up">#</div>
        <div class="column address-column has-text-left move-text-up">ADDRESS</div>
        <div class="column time-column has-text-centered">
          <div class="tooltip" v-show="tooltips[2]">
            Time on the road
            <div class="tooltip-point"></div>
          </div>
          <a class="button is-small has-text-weight-medium" v-on:click="showTool(2)">TOR</a>
        </div>
        <div class="column time-column has-text-centered">
          <div class="tooltip" v-show="tooltips[0]">
            Target time
            <div class="tooltip-point"></div>
          </div>
          <a class="button is-small has-text-weight-medium" v-on:click="showTool(0)">TGT</a>
        </div>
        <div class="column time-column has-text-centered">
          <div class="tooltip" v-show="tooltips[1]">
            Estimated delivery time
            <div class="tooltip-point"></div>
          </div>
          <a class="button is-small has-text-weight-medium" v-on:click="showTool(1)">EDT</a>
        </div>
        <div class="column time-column has-text-centered">
          <div class="tooltip" v-show="tooltips[3]">
            Actual delivery time
            <div class="tooltip-point"></div>
          </div>
          <a class="button is-small has-text-weight-medium" v-on:click="showTool(3)">ADT</a>
        </div>
        <div class="column new-time-column has-text-centered move-text-up">ENTRY</div>
        <div class="column new-time-column has-text-centered move-text-up">DELIVERY</div>
        <div class="column is-1 driver-column has-text-left move-text-up">DRIVER</div>
        <div class="column is-1 driver-column has-text-left move-text-up">REF ID</div>
        <div class="column is-1 button-column has-text-centered"></div>
    </div>

    <div class="order-details-area" v-if="deliveredOrders.length">
        <div class="columns table-rows is-size-7" v-for="o in deliveredOrders" v-bind:key="o.order_id" v-on:click="$emit('toggleOrderSelection', o)">

            <div class="column is-1 name-column has-text-left text-bold"
                :class="
                    selectedOrders.filter((s) => s == o.order_id).length === 1
                        ? 'has-background-link'
                        : ''
                    ">
                 {{ getShortName(o.customer_name).toUpperCase() }}
            </div>
            <div class="column is-1 order-number-column has-text-left">
                <img class="img source-logo" :src="getOrderSourceLogo(o.OrderSource)" />
            </div>
            <div class="column is-1 order-number-column has-text-left">
                {{ o.source_id ? getShortOrderNumber(o.source_id ) : o.order_number }}
            </div>
            <div class="column address-column has-text-left">{{ (o.address).toUpperCase() }}</div>
            <div class="column time-column has-text-centered">
                {{  o.time_on_road && o.time_on_road != -1 ? o.time_on_road : "--" }}
            </div>
            <div class="column time-column has-text-centered">
                {{ o.target_delivery_time_minutes }}
            </div>
            <div class="column time-column has-text-weight-bold has-text-centered" 
                    :class=" o.expected_time_minutes || o.ExternalEstimatedTimeToDeliver ?
                        o.target_delivery_time_minutes + 10 < (o.expected_time_minutes != null && (o.external_delivery_id == null || o.external_delivery_id == '') 
                        ? o.expected_time_minutes :
                         -stringTimeDiff(o.entry_time, o.ExternalEstimatedTimeToDeliver))
                        ? 
                        ' will-be-late has-text-weight-bold '
                        : 
                        o.target_delivery_time_minutes < (o.expected_time_minutes != null && (o.external_delivery_id == null || o.external_delivery_id == '') 
                        ? o.expected_time_minutes :
                         -stringTimeDiff(o.entry_time, o.ExternalEstimatedTimeToDeliver))
                        ? 
                        'orange-color has-text-weight-bold' 
                        : 
                        'green-color has-text-weight-bold' : ''
                    "
                >
                    {{ o.expected_time_minutes != null && (o.external_delivery_id == null || o.external_delivery_id == '') ? o.expected_time_minutes :
                        o.ExternalEstimatedTimeToDeliver ?
                         -stringTimeDiff(o.entry_time, o.ExternalEstimatedTimeToDeliver) : "--" }}
            </div>
            <div class="column time-column has-text-weight-bold has-text-centered" 
                :class="
                    o.target_delivery_time_minutes + 10 < -stringTimeDiff(o.entry_time, o.delivery_time)
                    ? 
                    ' will-be-late has-text-weight-bold '
                    : 
                    o.target_delivery_time_minutes < -stringTimeDiff(o.entry_time, o.delivery_time) 
                    ? 
                    'orange-color has-text-weight-bold' 
                    : 
                    'green-color has-text-weight-bold'
                "
            >
                {{ -stringTimeDiff(o.entry_time, o.delivery_time)}}
            </div>
            <div class="column new-time-column has-text-centered">
                {{ new Date(parseInt(o.entry_time.substr(6))).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: true}) }}
            </div>
            <div class="column new-time-column has-text-centered">
                {{ (o.delivery_time) ? new Date(parseInt((o.delivery_time).substr(6))).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: true}) : '--' }}
            </div>
            <div class="column is-1 driver-column has-text-left">
                {{
                    o.driver_id ? driverShortName(o.driver_id) :
                    o.external_delivery_source.toUpperCase()
                }}
            </div>
            <div class="column is-1 driver-column has-text-left">
                    {{ o.external_delivery_id ? getShortOrderNumber(o.external_delivery_id ) : '--' }}
            </div>
            <div class="column is-1 button-column has-text-centered">
                <a class="button is-outlined is-primary is-small" v-on:click="confirmRemakeOrder(o)">
                    <span class="fa fa-refresh" title="Remake"></span>
                </a>
            </div>
        </div>
    </div>
</div>
</template>

<script>
var timeFunctions = require("../../js/timeFunctions.js");
var orderFunctions = require("../../js/orderFunctions.js");

module.exports = {
    components: {},
    data: function () {
        return {
            tooltips: [false,false,false,false]
        };
    },
    created: function () {},
    computed: {
        getDrivers: function () {
            return this.$store.getters["drivers/getDrivers"];
        },
        selectedOrders: function () {
            return this.$store.getters["orders/selectedOrders"];
        },
        deliveredOrders: function () {
            const deliveredOrders = (this.$store.getters["orders/deliveredOrders"] || [])
            deliveredOrders.sort((a, b) => {
                const timeA = new Date(parseInt(a.delivery_time.substring(6)));
                const timeB = new Date(parseInt(b.delivery_time.substring(6)));
                return timeB - timeA;
            });
            return deliveredOrders
        },
    },
    watch: {},
    methods: {
        driverShortName(driverId) {
            const matchedDriver = this.getDrivers.find(d => d.driver_id === driverId);
            return matchedDriver ? this.getDriverShortName(matchedDriver.driver_name, matchedDriver.DriverLastName, 7).toUpperCase() : "";
        },
        showTool(a) {
            for(var i = 0; i < this.tooltips.length; i++){
                this.$set(this.tooltips, i, false);
            }
            this.$set(this.tooltips, a, true);
            setTimeout(() => this.$set(this.tooltips, a, false), 5000)
        },
        elapsedTime: (a) => timeFunctions.elapsedTime(a),
        stringTimeDiff: (a, b) => timeFunctions.stringTimeDiff(a, b),
        shortAddress: (address) => orderFunctions.getShortAddress(address),
        formatTime: (a) => timeFunctions.formatTime(a),
        getShortName: (name) => orderFunctions.getShortName(name),
        getDriverShortName: (fname, lname, limitFirstName) => orderFunctions.getDriverShortName(fname, lname, limitFirstName),
        confirmRemakeOrder: function (order) {
            this.$confirm("You are attempting to reset this order to it's original status. Confirm to proceed!").then(() => {
                this.$emit('remakeOrder', order, true)
            });
        },
        getOrderSourceLogo: (source) => orderFunctions.getOrderSourceLogo(source),
        getShortOrderNumber: (orderNumber) => orderFunctions.getShortOrderNumber(orderNumber),
    },
    filters: {},
};
</script>

<style scoped>
.order-details-area{
    max-height: calc(100vh - 140px);
    overflow-y: auto;
}
</style>
