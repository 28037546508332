<template>
    <div class="order-detail-page">
        <request-pickup-modal :open="Boolean(selectedOrder)" :onClose="() => selectedOrder = null"
            :order="selectedOrder" />
        <request-cancel-modal :open="Boolean(selectedOrderToCancel)" :onClose="() => selectedOrderToCancel = null"
            :order="selectedOrderToCancel" />
        <!-- Pickup Orders -->
        <div class="columns table-headings is-size-7">
            <div class="column is-1 name-column has-text-left move-text-up">NAME</div>
            <div class="column is-1 order-number-column has-text-left move-text-up">SOURCE</div>
            <div class="column is-1 order-number-column has-text-left move-text-up">#</div>
            <div class="column time-column has-text-centered">
                <div class="tooltip" v-show="tooltips[0]">
                    Elapsed time
                    <div class="tooltip-point"></div>
                </div>
                <a class="button is-small has-text-weight-medium" v-on:click="showTool(0)">ELP</a>
            </div>
            <div class="column time-column has-text-centered">
                <div class="tooltip" v-show="tooltips[1]">
                    Time until ready
                    <div class="tooltip-point"></div>
                </div>
                <a class="button is-small has-text-weight-medium" v-on:click="showTool(1)">TUR</a>
            </div>
            <div class="column is-1 order-number-column has-text-left move-text-up">TYPE</div>
            <div class="column is-1 button-column has-text-centered"></div>
            <div class="column is-1 button-column has-text-centered"></div>
        </div>

        <!-- nextRouteOrders -->
        <div class="order-details-area" v-if="pickUpOrders.length">
            <div class="columns table-rows is-size-7" v-for="o in pickUpOrders" v-bind:key="o.order_id" v-on:click="$emit('toggleOrderSelection', o)">
                <div class="column is-1 name-column has-text-left text-bold"
                    :class="{
                        'has-background-link': selectedOrders.filter((s) => s == o.order_id).length === 1,
                        '': selectedOrders.filter((s) => s == o.order_id).length !== 1
                    }">
                    {{ getShortName(o.customer_name).toUpperCase() }}
                </div>
                <div class="column is-1 order-number-column has-text-left">
                    <img class="img source-logo" :src="getOrderSourceLogo(o.OrderSource)" />
                </div>
                <div class="column is-1 order-number-column has-text-left">
                    {{ o.source_id  ? getShortOrderNumber(o.source_id ) : o.order_number }}
                </div>
                <!-- <div class="column is-3 address-column has-text-left">{{ (o.address).toUpperCase() }}</div> -->
                <div class="column time-column has-text-centered">
                    {{ elapsedTime(o.entry_time) }}
                    <!-- ELP -->
                </div>
               
                <div class="column time-column has-text-centered"
                    :class="{ 'green-color has-text-weight-bold': o.is_ready_to_deliver }">
                    {{ o.is_ready_to_deliver ? 'R' : Math.max(averagePrepTime - elapsedTime(o.entry_time), 0) }}
                </div>


                <div class="column time-column has-text-centered">
                    {{  o.OrderType === 'Pickup' ? "Pickup" : "Delivery" }}
                </div>
               
                <div class="column is-1 status-column has-text-centered">
                    <a class="button is-small is-primary ready-button" v-bind:class="[
                        { 'is-outlined': !o.is_ready_to_deliver },
                        {
                            'is-loading': orderStatusChanging.indexOf(o.order_id) != -1,
                        }
                    ]" v-on:click.stop="() => !o.delivery_time && $emit('changeOrderStatus', o)"
                    :disabled="o.delivery_time || o.PickupTime">
                        {{ o.is_ready_to_deliver ? "Ready" : "Not Ready" }}
                    </a>
                </div>

                <div v-if="o.OrderType === 'Pickup'"
                    class="column is-1 button-column has-text-centered">
                    <a class="button is-outlined is-info is-small"
                        v-on:click="!o.delivery_time && confirmCompletePickupOrder(o)"
                        :disabled="isOrderBeingCompleted[o.order_id] || o.delivery_time || o.PickupTime">
                        <span v-if="!isOrderBeingCompleted[o.order_id]" class="fa fa-exchange"></span>
                        <span v-else class="fa fa-spinner fa-spin"></span>
                    </a>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
var timeFunctions = require("../../js/timeFunctions.js");
var orderFunctions = require("../../js/orderFunctions.js");
var requestPickupModal = require("../../components/add_edit_order/request_pickup_modal").default;
var requestCancelModal = require("../../components/add_edit_order/request_cancel_modal").default;
// const { convertEasternTime } = require("../../js/timeFunctions.js");

module.exports = {
    components: {
        requestPickupModal,
        requestCancelModal,
    },
    data: function () {
        return {
            api_url: this.$store.state.api_url,
            selectedOrder: null,
            selectedOrderToCancel: null,
            tooltips: [false, false],
            isOrderBeingCompleted: {},
        };
    },
    created: function () { },
    computed: {
        orderStatusChanging: function () {
            return this.$store.getters["orders/orderStatusChanging"];
        },
        pickUpOrders: function () {
            const orders = [
                ...(this.$store.getters["orders/unroutedOrders"] || []),
                ...(this.$store.getters["orders/assignedOrders"] || []),
                ...(this.$store.getters["orders/inStoreNotNext"] || []),
                ...(this.$store.getters["orders/nextRoute"] || [])
            ];

            const uniqueOrders = orders.reduce((unique, order) => {
                if (!unique.orderIds.has(order.order_id)) {
                    unique.orderIds.add(order.order_id);
                    unique.orders.push(order);
                }
                return unique;
            }, { orderIds: new Set(), orders: [] }).orders;

            uniqueOrders.sort((orderA, orderB) => {
                const elapsedTimeA = this.elapsedTime(orderA.entry_time);
                const elapsedTimeB = this.elapsedTime(orderB.entry_time);
                return elapsedTimeA - elapsedTimeB;
            });

            return uniqueOrders;
        },
        selectedOrders: function () {
            return this.$store.getters["orders/selectedOrders"];
        },
        averagePrepTime: function () {
            return this.$store.getters["orders/averagePrepTime"];
        },
    },
    watch: {},
    methods: {
        confirmCompletePickupOrder: function (order) {
            this.$confirm("You are attempting to complete this pickup order. Confirm to proceed!").then(() => {
                this.setPickupOrderAsComplete(order);
            });
        },
        setPickupOrderAsComplete(order){
            this.$set(this.isOrderBeingCompleted, order.order_id, true);

            this.$http
                .post(this.api_url + "Api/V1/Orders/CompletePickupOrder/" + order.order_id)
                .then(
                    (res) => {
                        console.log("PICKUP ORDER COMPLETED SUCCESSFULLY");
                    },
                    (res) => {
                        console.log("Failed to complete pickup order", res);
                    }
                )
                .finally(() => {
                    this.$set(this.isOrderBeingCompleted, order.order_id, false);
                });
        },
        showTool(a) {
            for(var i = 0; i < this.tooltips.length; i++){
            this.$set(this.tooltips, i, false);
            }
            this.$set(this.tooltips, a, true);
            setTimeout(() => this.$set(this.tooltips, a, false), 5000)
        },
        elapsedTime: (a) => timeFunctions.elapsedTime(a),
        stringTimeDiff: (a, b) => timeFunctions.stringTimeDiff(a, b),
        shortAddress: (address) => orderFunctions.getShortAddress(address),
        getShortName: (name) => orderFunctions.getShortName(name),
        getOrderSourceLogo: (source) => orderFunctions.getOrderSourceLogo(source),
        getShortOrderNumber: (orderNumber) => orderFunctions.getShortOrderNumber(orderNumber),
        showOrderInfoModal: function (orderId) {
            this.$store.dispatch("orders/setEditOrder", orderId);
            this.$store.dispatch("app/setPanel", "editOrder");
            this.$store.dispatch('app/editOrderScreenReadOnly', true);
        },
        toggleRequestModal: function (order) {
            this.selectedOrder = order;
        },
        cancelRequestModal: function (order) {
            this.selectedOrderToCancel = order;
        },
        orderPickup: function (order) {
            let updatedOrder = {
                ...order,
                pending: false,
            }
            this.$store.dispatch("orders/saveEditOrder", updatedOrder);
        },
        setDelivered: function (order) {
            let updatedOrder = {
                ...order,
                delivery_time: new Date().toISOString(),
                overrideFields: false
            };
            this.$store.dispatch("orders/saveEditOrder", updatedOrder);
        },
        currentTime: () => timeFunctions.currentTime(),
        convertToTime: (a) => timeFunctions.convertToTime(a),
    },
    filters: {},
};
</script>

<style scoped>
.table-rows-top-group {
    padding-bottom: 1rem;
}

.source-logo {
    width: 1.4rem;
}

.delivered-item {
    color: #05A13C;
}
.order-details-area{
    max-height: calc(100vh - 140px);
    overflow-y: auto;
}

.ready-button, .status-column{
    width: 100px !important;
}

.complete-column, .complete-button{
    width: 150px !important;
}
</style>
