import Vuex from 'vuex';
import Vue from 'vue';

// import modules
import drivers from './modules/drivers.js';
import orders from './modules/orders.js';
import routes from './modules/routes.js';
import user from './modules/user.js';
import app from './modules/app.js';

import NotificationModal from '../components/notification_modal.vue';

Vue.use(Vuex);
Vue.component('notification-modal', NotificationModal);

export default new Vuex.Store({
  // Register vuex modules
  state: {
    base_url: process.env.VUE_APP_API_URL,
    hub_url: process.env.VUE_APP_HUB_URL,
    api_url: process.env.VUE_APP_AUTH_API_URL,
    stripe_publishable_key: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    order_url: "",
    settings: {},
    settingsUrls: {},
    connection: null,
    showNotificationModal: false,
    notificationMessage: "",
  },
  modules: {
    drivers: drivers,
    orders: orders,
    routes: routes,
    user: user,
    app: app,
  },
  getters: {
    getStoreID: (state, getters, rootState) => {
      return rootState.user.store_id;
    },
    settings: (state) => {
      return state.settings;
    },
    settingsUrls: (state) => {
      return state.settingsUrls;
    }
  },
  actions: {
    logout({ commit }) {
      commit('drivers/reset');
      commit('orders/reset');
      commit('routes/reset');
      commit('user/logout');
      commit('user/navigateHome');
    },
    getSettings({ getters, dispatch, state }) {
      var payload = { store_id: getters.getStoreID, method: "GET" };
      Vue.http.post(state.base_url + "settings/", payload).then((settings) => {
        dispatch("updateSettings", settings.body.settings[0]);
        dispatch("updateOrderUrl", settings.body.ontimeOrderUrl);

        if(settings.body.settings[0].firstDriverClockedIn && getters['app/getIsLocked']) {
          dispatch("app/unlockFromPanel", null, {
            root: true
          });
          dispatch("app/setPanel", 'order', {
            root: true
          });
        }

        const urlKeyValues = Object.fromEntries(
          Object.entries(settings.body).filter(([key]) => key.toLowerCase().includes("url"))
        );
        dispatch("updateSettingsUrls", urlKeyValues)
      });
    },
    updateSettingsUrls({ commit }, urls) {
      commit('updateSettingsUrls', urls);
    },
    updateSettings({ commit }, settings) {
      commit('updateSettings', settings);
    },
    updateOrderUrl({commit}, orderUrl){
      commit('updateOrderUrl', orderUrl);
    },
    async connect({ dispatch, commit, getters }) {
      /*eslint-disable no-undef*/
      // Create SignalR Connection
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(process.env.VUE_APP_HUB_URL + "consolehub?store_key=" + getters['user/getStoreKey'], 
          { skipNegotiation: true, transport: signalR.HttpTransportType.WebSockets })
        .configureLogging(signalR.LogLevel.Information)
        .build();

      async function start() {
        try {
          await connection.start();
          console.log("SignalR Connected.");
        } catch (err) {
          console.log("SignalR Connection Error", err);
          setTimeout(start, 5000);
        }
      }

      connection.onclose(start);

      connection.on("UpdateSettings", (settingData) => {
        let parsedResponse = JSON.parse(settingData)
        console.debug("SignalR Settings Update", parsedResponse);
        commit("updateSettings", parsedResponse);
      });

      connection.on("UpdateOrders", (orderData) => {
        let parsedResponse = JSON.parse(orderData);
        if (parsedResponse.result != 0) {
          alert(parsedResponse.error);
        }
        console.debug("SignalR Orders Update", parsedResponse.order_info);
        dispatch("orders/updateOrders", parsedResponse.order_info);
      });

      connection.on("UpdateDrivers", (driverData) => {
        let parsedResponse = JSON.parse(driverData);
        if (parsedResponse.result != 0) {
          alert(parsedResponse.error);
        }
        console.debug("SignalR Drivers Update", parsedResponse.driver_info);
        dispatch("drivers/updateDrivers", parsedResponse.driver_info);
      });

      connection.on('ReceiveNotification', (message) => {
        console.log("Received notification message from SignalR")
        // Dispatch the showNotificationModal mutation with the received message
        commit('showNotificationModal', message);
      });

      connection.onreconnecting(error => {
        console.assert(connection.state === signalR.HubConnectionState.Reconnecting);
        console.error("Error Reconnecting to SignalR", error);
      });

      connection.onreconnected(connectionId => {
        console.assert(connection.state === signalR.HubConnectionState.Connected);
        console.log("Reconnected to SignalR", connectionId);
      });
      /*eslint-enable no-undef*/
      // Start the connection.
      await start();

      commit("connect", connection);
    },
  },
  mutations: {
    updateSettings(state, settings) {
      state.settings = settings;
    },
    updateOrderUrl(state, orderUrl){
      state.order_url = orderUrl;
    },
    updateSettingsUrls(state, urls){
      state.settingsUrls = urls;
    },
    connect(state, connection) {
      state.connection = connection;
    },
    showNotificationModal(state, message) {
      state.notificationMessage = message;
      state.showNotificationModal = true;
    },
    hideNotificationModal(state) {
      state.showNotificationModal = false;
    },
  },
});
