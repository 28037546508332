<template>
  <div class="card">
    <!------- start Table Header ----->
    <header class="card-header">
      <p class="card-header-title">Reports</p>
    </header>
    <!------ end Table Header ----->

    <div class="card-content reports-pane">
      <div slot="body">

        <div class="columns">
          <div class="column is-1">
              <a class="button ready-button is-link" v-on:click="changeDateToToday()">Today</a>
            </div>

            <div class="column is-3 is-pulled-left">
              <input class="input" type="datetime-local" v-model="accountReport.startDate" />
            </div>
            
            <div class="column is-3 is-pulled-left">
              <input class="input" type="datetime-local" v-model="accountReport.endDate" />
            </div>

            <div class="column is-3 selectWrap">
              <select id="dateSelect" @change="onCloseDateChange($event); searchDriverOrders()" style="width: 100%">
                <option value="">Select Closed Day</option>
                <option v-for="cs in closedDates" :key="cs.dayId" :value="cs.dayId">
                  {{ "DayId: " + cs.dayId + ", " + formatDateTime(cs.openDateTime, true) + " - " + formatDateTime(cs.closeDateTime, true) }}
                </option>
              </select>
            </div>

            <div class="column is-1 has-text-center" v-if="tab == 3">
              <a
                class="
                  button
                  is-header-button
                  big-button-header
                  is-info
                  is-light
                "
              >
                <i style="color: #37c936" @click="exportData" class="fa fa-file-excel-o"></i>
              </a>
            </div>
        </div>

        <div class="tabs is-centered is-toggle is-small is-fullwidth">
          <ul>
          <li v-bind:class="{ 'is-active': tab == 1 }">
              <a v-on:click="changeTab(1)">SALES REPORT</a>
          </li>
          <li v-bind:class="{ 'is-active': tab == 2 }">
              <a v-on:click="changeTab(2)">CREDIT REPORT</a>
          </li>
          <li v-bind:class="{ 'is-active': tab == 3 }">
              <a v-on:click="changeTab(3)">TIP REPORT</a>
          </li>
          <li v-bind:class="{ 'is-active': tab == 4 }">
              <a v-on:click="changeTab(4)">DELIVERY PERFORMANCE REPORT</a>
          </li>
          </ul>
        </div>

        <div v-if="tab === 1">
          <div class="is-multiline is-mobile">
            <!-- Date and source logo/name-->
            <div class="columns is-size-7">
              <div class="column is-1-5 address-column has-text-left bold">DAY STARTED {{ accountReport.startDate ? formatDateTime(accountReport.startDate, true) : store.isOpen ? formatDateTime(openDayFile.openDateTime, true) : 'Closed' }}</div>
              <div class="column order-number-column has-text-left" v-for="(source) in orderSources">
                <img class="img source-logo total-logo" :src="getSourceLogo(source.OrderSource)" v-bind:alt="(source.OrderSource)" />
              </div>
              <div><i class="space-report" v-if="orderSources.length"></i></div> 
              <div class="column order-number-column has-text-left bold" v-if="orderSources.length">TOTALS</div>
            </div>

            <!-- Orders-->
            <div class="columns is-size-7 table-today-orders-rows">
              <div class="column status-column has-text-right bold">ORDERS</div>
              <div><i class="space-report" v-if="orderSources.length"></i></div>
              <div class="column order-number-column has-text-left" v-for="(source) in orderSources">
                {{ source.orders }}
              </div>
              <div><i class="space-report" v-if="orderSources.length"></i></div>
              <div class="column order-number-column has-text-left" style="min-width: 68px;">{{ currentDayOrders.length }}</div>
            </div>

            <!-- Sales-->
            <div class="columns is-size-7 table-today-orders-rows">
              <div class="column status-column has-text-right bold">SALES</div>
              <div><i class="space-report" v-if="orderSources.length"></i></div>
              <div class="column order-number-column has-text-left" v-for="(source) in orderSources">
                {{ source.sales | formatAmount }}
              </div>
              <div><i class="space-report" v-if="orderSources.length"></i></div> 
              <div class="column order-number-column has-text-left" style="min-width: 68px;">{{ totalSales | formatAmount}}</div>
            </div>

            <!-- AVG TIP -->
            <div class="columns is-size-7 table-today-orders-rows">
              <div class="column status-column has-text-right bold">AVG TIP</div>
              <div><i class="space-report" v-if="orderSources.length"></i></div>
              <div class="column order-number-column has-text-left" v-for="(source) in orderSources">
                {{ source.avgTip | formatAmount }}
              </div>
              <div><i class="space-report" v-if="orderSources.length"></i></div>
              <div class="column order-number-column has-text-left" style="min-width: 68px;">{{ avgTip | formatAmount}}</div>
            </div>

            <!-- TOTAL TIPS -->
            <div class="columns is-size-7 table-today-orders-rows">
              <div class="column status-column has-text-right bold">TIPS</div>
              <div><i class="space-report" v-if="orderSources.length"></i></div>
              <div class="column order-number-column has-text-left" v-for="(source) in orderSources">
                {{ source.tips | formatAmount }}
              </div>
              <div><i class="space-report" v-if="orderSources.length"></i></div>
              <div class="column order-number-column has-text-left" style="min-width: 68px;">{{ totalTips | formatAmount}}</div>
            </div>

            <!-- IN HOUSE TIPS -->
            <div class="columns is-size-7 table-today-orders-rows">
              <div class="column status-column has-text-right bold">IN-HOUSE TIPS</div>
              <div><i class="space-report" v-if="orderSources.length"></i></div>
              <div class="column order-number-column has-text-left" v-for="(source) in orderSources">
                {{ source.inHouseTips | formatAmount }}
              </div>
              <div><i class="space-report" v-if="orderSources.length"></i></div>
              <div class="column order-number-column has-text-left" style="min-width: 68px;">{{ inHouseTips | formatAmount}}</div>
            </div>

            <!-- OUTSOURCED TIPS -->
            <div class="columns is-size-7 table-today-orders-rows">
              <div class="column status-column has-text-right bold">OUTSOURCED TIPS</div>
              <div><i class="space-report" v-if="orderSources.length"></i></div>
              <div class="column order-number-column has-text-left" v-for="(source) in orderSources">
                {{ source.outSourceTips | formatAmount }}
              </div>
              <div><i class="space-report" v-if="orderSources.length"></i></div>
              <div class="column order-number-column has-text-left" style="min-width: 68px;">{{ outSourcedTips | formatAmount}}</div>
            </div>

          </div>
        </div>

        <div v-if="tab === 2">
          <div class="is-multiline is-mobile">
              <!-- <div class="columns">
                <div class="column is-2">
                <a class="button is-link" v-on:click="selectedDate = getCurrentMonth(); fetchTransactions();">This Month</a>
              </div>
              <div class="date-picker-container column is-3">
                  <input class="input" type="month" id="monthFilter" v-model="selectedDate" @change="fetchTransactions" />
              </div>
            </div> -->

            <br>
        
            <div class="columns is-size-7">
              <div class="column name-column has-text-left text-bold text-upper-case credit-report-number-column">Orders Requested</div>
              <div class="column order-number-column has-text-left credit-report-number-column">{{ ordersRequested }}</div>
              
              <div class="column name-column has-text-left text-bold text-upper-case credit-report-number-column">Avg fee per Order</div>
              <div class="column order-number-column has-text-left credit-report-number-column">{{ ordersRequested != 0 ? moneyFormat(feesPaid/ordersRequested) : moneyFormat(0) }}</div>

              <div class="column name-column has-text-left text-bold text-upper-case credit-report-number-column">Fees Collected</div>
              <div class="column order-number-column has-text-left credit-report-number-column">{{ moneyFormat(deliveryFee * ordersRequested) }}</div>
            </div>

            <div class="columns is-size-7">
              <div class="column name-column has-text-left text-bold text-upper-case credit-report-number-column">Starting Balance</div>
              <div class="column order-number-column has-text-left credit-report-number-column">{{ moneyFormat(feesPaid + totalTipsPaid + storeInfo.credits - creditsBought) }}</div>

              <div class="column name-column has-text-left text-bold text-upper-case credit-report-number-column">Total Fees Paid</div>
              <div class="column order-number-column has-text-left credit-report-number-column">{{ moneyFormat(feesPaid) }}</div>

              <div class="column name-column has-text-left text-bold text-upper-case credit-report-number-column">Tips Collected</div>
              <div class="column order-number-column has-text-left credit-report-number-column">{{ moneyFormat(collectedTips) }}</div>
            </div>

            <div class="columns is-size-7">
              <div class="column name-column has-text-left text-bold text-upper-case credit-report-number-column">Credits Purchased</div>
              <div class="column order-number-column has-text-left credit-report-number-column">{{ moneyFormat(creditsBought) }}</div>

              <div class="column name-column has-text-left text-bold text-upper-case credit-report-number-column">Total Tips Paid</div>
              <div class="column order-number-column has-text-left credit-report-number-column">{{ moneyFormat(totalTipsPaid) }}</div>

              <div class="column name-column has-text-left text-bold text-upper-case credit-report-number-column">Total Collected</div>
              <div class="column order-number-column has-text-left credit-report-number-column">{{ moneyFormat(collectedTips + deliveryFee * ordersRequested) }}</div>
            </div>

            <br>
            
            <div class="columns is-size-7">
              <div class="column name-column has-text-left text-bold text-upper-case credit-report-number-column">Total Credits</div>
              <div class="column order-number-column has-text-left credit-report-number-column">{{ moneyFormat((feesPaid + totalTipsPaid + storeInfo.credits - creditsBought) + creditsBought) }}</div>

              <div class="column name-column has-text-left text-bold text-upper-case credit-report-number-column">Total Cost</div>
              <div class="column order-number-column has-text-left credit-report-number-column">{{ moneyFormat(feesPaid + totalTipsPaid) }}</div>

              <div class="column name-column has-text-left text-bold text-bold text-upper-case credit-report-number-column">Available Credit</div>
              <div class="column order-number-column has-text-left credit-report-number-column">{{ moneyFormat(storeInfo.credits || 0) }}</div>

              <!-- <div class="column text-bold add-credit-column">
                <a class="is-link white-text" v-on:click="goToAddCreditScreen()">
                  ADD CREDIT
                </a>
              </div> -->
            </div>

            <br>
            <hr>

                        <br>
            <table class="table is-fullwidth">
              <thead>
                  <tr>
                      <!-- <th></th> -->
                      <th class="has-text-center">Source</th>
                      <th class="has-text-center">#</th>
                      <th class="has-text-center">Fee</th>
                      <th class="has-text-center">Tip</th>
                      <th class="has-text-center">Transaction Type</th>
                      <th class="has-text-center">Company</th>
                      <th class="has-text-center">Transaction Date</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="(transaction, index) in transactions" :key="transaction.transactionId">
                      <!-- <td>{{ index + 1 }}</td> -->
                      <td class="has-text-center">
                        <img class="img source-logo" :src="getOrderSourceLogo(transaction.OrderSource)" />
                        <!-- <div class="column is-1 order-number-column has-text-left">
                            <img class="img source-logo" :src="getOrderSourceLogo(transaction.OrderSource)" />
                        </div> -->
                      </td>
                      <td class="has-text-center">{{ transaction.sourceId ? getShortOrderNumber(transaction.sourceId) : transaction.orderNumber ? transaction.orderNumber : "-" }}</td>
                      <td class="has-text-center"
                          v-bind:class="{ 'negative-amount': transaction.transactionType === 'Outsourced', 'positive-amount': transaction.transactionType === 'Credit' || transaction.transactionType === 'CancelingOutsourcedOrder' }">
                          {{ transaction.transactionType === 'Outsourced' ? '-' : '+' }} {{ moneyFormat(transaction.amount) }}
                      </td>
                      <td class="has-text-center">{{ transaction.tip || '-' }}</td>
                      <td class="has-text-center">{{ transaction.transactionType.replace(/([A-Z])/g, ' $1').trim() }}</td>
                      <td class="has-text-center">{{ transaction.deliveryCompany || '-' }}</td>
                      <td class="has-text-center">{{ formatDateTime(transaction.transactionDate, false) }}</td>
                  </tr>
              </tbody>
            </table>

          </div>
        </div>

        <div v-if="tab === 3">
          <div class="columns">
            <!-- <div class="column">
              <a class="button is-primary header-btn has-text-weight-medium mt-0" v-on:click="searchDriverOrders()">
                <i class="fa fa-search"></i>
                Search
              </a>
            </div> -->
          </div>

          <!-- ---start table---- -->
          <div>
            <div class="columns">
              <div class="column is-12">
                <table class="table is-bordered">
                  <thead>
                    <tr class="has-background-dark has-text-white">
                      <th class="has-text-white">DRIVER</th>
                      <th class="has-text-white"># ORDERS</th>
                      <th class="has-text-white">TIPS</th>
                      <th class="has-text-white">AVG. TIP TOTAL</th>
                      <th class="has-text-white">FEES</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody v-if="payRollReportOrders.length">
                    <template v-for="(payRollReportOrder, index) in payRollReportOrders">
                      <tr>
                        <td>
                          <b>{{ payRollReportOrder.driverName + (payRollReportOrder.driverLastName ? ` ${payRollReportOrder.driverLastName}` : "" ) }}</b>
                        </td>
                        <td>{{ payRollReportOrder.orderCount }}</td>
                        <td>{{ payRollReportOrder.tips | formatAmount }}</td>
                        <td>
                          {{ payRollReportOrder.averageTipTotal | formatAmount }}
                        </td>
                        <td>{{ payRollReportOrder.fees | formatAmount }}</td>
                        <td>
                          <!-- <a
                            class="
                              button
                              is-small
                              is-header-button
                              big-button-header
                              is-info
                              is-light
                            "
                            @click="onRowClick(index)"
                          >
                            <i style="color: #37c936" @click="exportData" class="fa fa-file-excel-o"></i>
                          </a> -->
                          <a
                            class="
                              button
                              is-small
                              is-header-button
                              is-pulled-right
                              big-button-header
                              is-info
                              is-light
                            "
                          >
                            <i @click="collapseExpandOrderList($event)" class="
                                fa fa-angle-down
                                collapse-expand-order-list-icon
                                fa-2x
                              " 
                              aria-hidden="true">
                            </i>
                          </a>
                        </td>
                      </tr>
        
                      <tr class="is-hidden order-list-table">
                        <td colspan="7">
                          <table>
                            <thead>
                              <tr class="has-background-grey-light">
                                <th class="has-text-white">DATE</th>
                                <th class="has-text-white">ORDER #</th>
                                <th class="has-text-white">TOTAL</th>
                                <th class="has-text-white">PMT</th>
                                <th class="has-text-white">TIP</th>
                                <th class="has-text-white">FEE</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="order in payRollReportOrder.orders" v-bind:key="order.orderId">
                                <td>{{ order.entryTime | formatDate }}</td>
                                <td>{{ order.sourceId ? getShortOrderNumber(order.sourceId) : order.orderNumber }}</td>
                                <td>{{ order.total | formatAmount }}</td>
                                <td>{{ order.payment }}</td>
                                <td>{{ order.tip | formatAmount }}</td>
                                <td>
                                  {{ order.driverDeliveryFee | formatAmount }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="7" class="has-text-centered">
                        <b>No records</b>
                      </td>
                    </tr>
                  </tbody>
        
                  <tfoot v-if="payRollReportOrders.length">
                    <tr>
                      <th>TOTALS:</th>
                      <th>{{ this.payrollReportTotals.totalOrders }}</th>
                      <th>
                        {{ this.payrollReportTotals.totalTips | formatAmount }}
                      </th>
                      <th>
                        {{
                        this.payrollReportTotals.totalAverageTipTotal
                        | formatAmount
                        }}
                      </th>
                      <th>
                        {{ this.payrollReportTotals.totalFees | formatAmount }}
                      </th>
                      <th></th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <!-- ---end table---- -->
        </div>

        <div v-if="tab === 4">
          <div class="delivery-performance-container">
            <div class="performance-container">
              <div class="multiselect-container">
                <div class="title-box">
                  <p class="store-performance-title">ALL DRIVERS</p>
                </div>
              </div>

              <div class="donut-chart-container">
                <canvas ref="storePerfomanceChart" style="width: 150px !important;"></canvas>
              </div>

              <div class="legend-container">
                <table class="numbers-table">
                  <tr>
                    <td class="text-bold">Delivered</td>
                    <td class="text-bold">{{ this.storePerformanceSumDelivered }}</td>
                  </tr>
                  <tr>
                    <td class="text-bold">Returned</td>
                    <td class="text-bold">{{ this.storePerformanceSumReturned }}</td>
                  </tr>
                  <tr>
                    <td class="text-bold">ETA Met</td>
                    <td class="text-bold">{{ (this.storePerformanceSumEtaMet / this.storePerformanceSumDelivered).toFixed(2) * 100 }}%</td>
                  </tr>
                  <!-- <tr>
                    <td class="bold">Travel Met</td>
                    <td class="bold">+3</td>
                  </tr> -->
                </table>
                <table class="chart-legend-table">
                  <tr>
                    <td class="text-bold"><div class="green-rectangle"></div>Early</td>
                    <td class="text-bold">({{ storeEarlyPercentage.toFixed(2) }}%)</td>
                  </tr>
                  <tr>
                    <td class="text-bold"><div class="yellow-rectangle"></div>On Time</td>
                    <td class="text-bold">({{ storeOnTimePercentage.toFixed(2) }}%)</td>
                  </tr>
                  <tr>
                    <td class="text-bold"><div class="red-rectangle"></div>Late</td>
                    <td class="text-bold">({{ storeLatePercentage.toFixed(2) }}%)</td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="vertical-line"></div>

            <div class="performance-container">
              <div class="multiselect-container">
                <!-- <label>Select Driver(s)</label> -->
                <multiselect
                    id="multiselect-driver"
                    v-model="selectedDriversToGetPerformance" 
                    :options="deliveryPerformanceDrivers" 
                    :multiple="true" 
                    :close-on-select="false" 
                    :clear-on-select="false" 
                    :preserve-search="true" 
                    placeholder="Select Driver"
                    label="driver_fullname" 
                    track-by="driver_id" 
                    :preselect-first="true">
                  </multiselect>
              </div>

              <div class="donut-chart-container">
                <canvas ref="driversPerfomanceChart" style="width: 150px !important;"></canvas>
              </div>

              <div class="legend-container">
                <table class="numbers-table">
                  <tr>
                    <td class="text-bold">Delivered</td>
                    <td class="text-bold">{{ this.sumDelivered }}</td>
                  </tr>
                  <tr>
                    <td class="text-bold">Returned</td>
                    <td class="text-bold">{{ this.sumReturned }}</td>
                  </tr>
                  <tr>
                    <td class="text-bold">ETA Met</td>
                    <td class="text-bold">{{ (this.sumEtaMet / this.sumDelivered).toFixed(2) * 100 }}%</td>
                  </tr>
                  <!-- <tr>
                    <td class="bold">Travel Met</td>
                    <td class="bold">+3</td>
                  </tr> -->
                </table>
                <table class="chart-legend-table">
                  <tr>
                    <td class="text-bold"><div class="green-rectangle"></div>Early</td>
                    <td class="text-bold">({{ earlyPercentage.toFixed(2) }}%)</td>
                  </tr>
                  <tr>
                    <td class="text-bold"><div class="yellow-rectangle"></div>On Time</td>
                    <td class="text-bold">({{ onTimePercentage.toFixed(2) }}%)</td>
                  </tr>
                  <tr>
                    <td class="text-bold"><div class="red-rectangle"></div>Late</td>
                    <td class="text-bold">({{ latePercentage.toFixed(2) }}%)</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { excelParser } from "../../js/excelParser";
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
var modal = require("../modal.vue").default;
var timeFunctions = require("../../js/timeFunctions.js");
var orderFunctions = require("../../js/orderFunctions.js");
var custom = require('../../js/custom')
import Vue from 'vue';
import moment from 'moment';
import Chart from 'chart.js/auto';
import Multiselect from 'vue-multiselect';
import { mounted } from "../address_textbox_generic.vue";

export default {
  components: {
    modal,
    Datepicker,
    custom,
    Multiselect
  },
  data: function () {
    return {
      tab: 1,
      minLength: 0,
      store_id: this.$store.state.user.store_id,
      base_url: this.$store.state.base_url,
      hub_url: this.$store.state.hub_url,
      isStoreOpen: true,
      newDriverModalStatus: false,
      driverReportModalStatus: false,
      driverPayrollModalStatus: false,
      driverIds: [],
      isDetails: false,
      dateFormat: "MM/dd/yyyy",
      api_url: this.$store.state.api_url,
      payRollReportOrders: [],
      closedDates: [],
      selectedDayId: "",
      totalSales: 0,
      avgTip: 0,
      totalTips: 0,
      inHouseTips: 0,
      outSourcedTips: 0,
      orderSources: [],
      transactions: [],
      selectedDate: '',
      ordersRequested: 0,
      creditsBought: 0,
      startingBalance: 0,
      feesPaid: 0,
      selectedDriversToGetPerformance: [],
      storePerfomanceChart: null,
      driversPerfomanceChart: null,
      driversPerfomanceData: [],
      ordersIdFetchedForBilling: [],
      collectedTips: 0,
      totalTipsPaid: 0,
      deliveryFee: 0,
      //for drivers performance chart
      sumDeliveredOnTime: 0, 
      sumEarly: 0, 
      sumLate: 0,
      sumEtaMet: 0,
      sumDelivered: 0,
      sumReturned: 0,
      storePerformanceSumDeliveredOnTime: 0, 
      storePerformanceSumEarly: 0, 
      storePerformanceSumLate: 0,
      storePerformanceSumEtaMet: 0,
      storePerformanceSumDelivered: 0,
      storePerformanceSumReturned: 0,
      currentDay: {},
      historicalOrders: []
    };
  },
  filters: {},
  computed: {
    settings: function() {
      return this.$store.getters["settings"];
    },
    deliveryPerformanceDrivers() {
      return this.drivers.map(driver => ({
        driver_id: driver.driver_id,
        driver_fullname: `${driver.driver_name} ${driver.DriverLastName || ''}`,
      }));
    },
    assignedRoute: function () {
      if (this.selectedDriver) {
        return this.$store.getters["orders/assignedOrdersByDriverId"](
          this.selectedDriver.driver_id
        );
      }
    },
    openDriversModalStatus() {
      return this.$store.state.orders.openDriversModalStatus;
    },
    openDrivers() {
      return this.$store.state.orders.openDrivers;
    },
    accountReport() {
      return this.$store.state.orders.accountReport;
    },
    selectedDriver() {
      return this.$store.getters["drivers/selectedDriver"];
    },
    drivers: function () {
      return this.$store.getters["drivers/getDrivers"];
    },
    store: function () {
      return this.$store.getters["orders/getStore"];
    },
    getIsOpenCloseAllDriverModalStatus: function () {
      return this.$store.getters["orders/getIsOpenCloseAllDriverModalStatus"];
    },
    onDutyDrivers: function () {
      return this.$store.getters["drivers/onDutyDrivers"];
    },
    openDayFile() {
      return this.$store.getters["orders/getOpenDayFile"];
    },
    storeInfo: function () {
      return this.$store.getters["orders/getStore"]
    },
    currentDayOrders: function () {
      this.getCurrentDayOrders();

      var storeTransaction =
        this.$store.getters["orders/getStoreCurrentDayTransaction"];
      if (storeTransaction && !storeTransaction.isOpen) {
        this.isStoreOpen = false;
      }
      this.storeSetting = this.$store.state.orders.store;

      if(this.accountReport.startDate && this.accountReport.endDate){
        var orders = this.historicalOrders.map(({ externalDeliverySource, orderSource, ...rest }) => ({
            external_delivery_source: externalDeliverySource,
            OrderSource: orderSource,
            ...rest
        }));
      }
      else{
        var orders = this.$store.getters["orders/getCurrentDayOrders"];
      }
      
      this.totalSales = 0;
      this.avgTip = 0;
      this.totalTips = 0;
      this.inHouseTips = 0;
      this.outSourcedTips = 0;
      this.orderSources = [];

      orders.forEach((o, i) => {
        let found = false;

        this.orderSources.forEach((s, j) => {
          // increment orderSource
          if(s.OrderSource === o.OrderSource) {
            found = true;
            this.orderSources[j].orders = this.orderSources[j].orders + 1
            this.orderSources[j].sales = this.orderSources[j].sales + o.total
            this.orderSources[j].tips = this.orderSources[j].tips + (o.payment !== "Cash" ? o.tip : 0)
            this.orderSources[j].inHouseTips = this.orderSources[j].inHouseTips + (o.payment !== "Cash" && !o.external_delivery_source ? o.tip : 0)
            this.orderSources[j].outSourceTips = this.orderSources[j].outSourceTips + (o.payment !== "Cash" && o.external_delivery_source ? o.outsourcedTip : 0)
            this.orderSources[j].avgTip = this.orderSources[j].tips/this.orderSources[j].orders
          }
        })

        // new orderSource
        if (!found) {
          this.orderSources.push({
            OrderSource: o.OrderSource,
            orders: 1,
            sales: o.total,
            avgTip: o.payment !== "Cash" ? o.tip : 0,
            tips: o.payment !== "Cash" ? o.tip : 0,
            inHouseTips: o.payment !== "Cash" && !o.external_delivery_source ? o.tip : 0,
            outSourceTips: o.payment !=="Cash" && o.external_delivery_source ? o.outsourcedTip : 0
          })
        }


        // totals

        this.totalSales = this.totalSales + o.total
        this.totalTips = this.totalTips + (o.payment !== "Cash" ?  + o.tip : 0)
        this.inHouseTips =  this.inHouseTips + (o.payment !== "Cash" && !o.external_delivery_source ? o.tip : 0)
        this.outSourcedTips =  this.outSourcedTips + (o.payment !== "Cash" && o.external_delivery_source ? o.outsourcedTip : 0)
        this.avgTip = this.totalTips / orders.length
      });

      // find withouth cash payment orders
      this.withOutCashOrders = orders
        .map((item) => item.payment != "Cash")
        .reduce((prev, curr) => prev + curr, 0);

      // Get total tip
      this.totalTip = orders
        .map((item) => item.payment != "Cash" && item.tip && !item.external_delivery_source)
        .reduce((prev, curr) => prev + curr, 0);

      // Get total orders amount
      this.totalAmount = parseFloat(
        orders.map((item) => item.total).reduce((prev, curr) => prev + curr, 0)
      )?.toFixed(2);
      // Get total orders deliveryFees
      this.totalDeliveryFees = parseFloat(
        orders
          .map((item) => item.driverDeliveryFee)
          .reduce((prev, curr) => prev + curr, 0)
      )?.toFixed(2);
      //Total Fees Negative if the settings are set to be paid at the end of the shift
      if (this.storeSetting.isDriverFeePaidAtClosing) {
        this.totalDeliveryFees = -Math.abs(this.totalDeliveryFees);
      }
      this.totalDeliveryFeesAverage = (
        this.totalDeliveryFees / orders.length
      )?.toFixed(2);

      // Get total orders cash Orders Amount Total
      this.cashOrdersAmountTotal = parseFloat(
        orders
          .map((item) => item.payment == "Cash" && item.total)
          .reduce((prev, curr) => prev + curr, 0)
      )?.toFixed(2);
      // get total amount average
      this.averageAmount = (this.totalAmount / orders.length).toFixed(2);
      // get total tip average
      this.totalTipAverage = (this.totalTip / this.withOutCashOrders)?.toFixed(
        2
      );
      this.openingDriverCash = parseFloat(
        orders.map((item) => Math.abs(item.driverAmount))
      )?.toFixed(2);
      this.withOutCashOrdersList = orders.filter((x) => x.payment == "Cash");
      this.cashOwed = orders
        .map((item) => item.cashedOwed)
        .reduce((prev, curr) => prev + curr, 0);

      //Driver Fee Paid At Closing is true then Total Cash + Opening Cash - TOTAL FEES
      if (
        this.storeSetting.isDriverFeePaidAtClosing &&
        !this.storeSetting.isNonCashOrdersTipsPaidAtClosing
      ) {
        this.cashOwed = (
          parseFloat(this.cashOrdersAmountTotal) +
          parseFloat(this.openingDriverCash) -
          parseFloat(this.totalDeliveryFees)
        )?.toFixed(2);

      // Non-Cash Tips Paid At Closing is true then Total Cash + Opening Cash - Total tips
      } else if (
        this.storeSetting.isNonCashOrdersTipsPaidAtClosing &&
        !this.storeSetting.isDriverFeePaidAtClosing
      ) {
        this.cashOwed = (
          parseFloat(this.cashOrdersAmountTotal) +
          parseFloat(this.openingDriverCash) -
          parseFloat(this.totalTip)
        )?.toFixed(2);

      //Non-Cash Tips Paid At Closing and Driver Fee Paid At Closing both are true
      } else if (
        this.storeSetting.isNonCashOrdersTipsPaidAtClosing &&
        this.storeSetting.isDriverFeePaidAtClosing
      ) {
        this.cashOwed = (
          parseFloat(this.cashOrdersAmountTotal) +
          parseFloat(this.openingDriverCash) -
          parseFloat(this.totalTip) -
          parseFloat(this.totalDeliveryFees)
        )?.toFixed(2);
      }

      //Non-Cash Tips Paid At Closing and Driver Fee Paid At Closing both are false
      else if (
        !this.storeSetting.isNonCashOrdersTipsPaidAtClosing &&
        !this.storeSetting.isDriverFeePaidAtClosing
      ) {
        this.cashOwed = (
          parseFloat(this.cashOrdersAmountTotal) +
          parseFloat(this.openingDriverCash)
        )?.toFixed(2);
      }
      return orders;
    },
    startAndEndDate() {
      return [this.accountReport.startDate, this.accountReport.endDate];
    },
    totalDeliveries() {
      return this.sumEarly + this.sumDeliveredOnTime + this.sumLate || 0;
    },
    storePerformanceTotalDeliveries() {
      return this.storePerformanceSumEarly + this.storePerformanceSumDeliveredOnTime + this.storePerformanceSumLate || 0;
    },
    earlyPercentage() {
      return (this.sumEarly / this.totalDeliveries * 100) || 0;
    },
    onTimePercentage() {
      return (this.sumDeliveredOnTime / this.totalDeliveries * 100) || 0;
    },
    latePercentage() {
      return (this.sumLate / this.totalDeliveries * 100) || 0;
    },
    storeEarlyPercentage() {
      return (this.storePerformanceSumEarly / this.storePerformanceTotalDeliveries * 100) || 0;
    },
    storeOnTimePercentage() {
      return (this.storePerformanceSumDeliveredOnTime / this.storePerformanceTotalDeliveries * 100) || 0;
    },
    storeLatePercentage() {
      return (this.storePerformanceSumLate / this.storePerformanceTotalDeliveries * 100) || 0;
    }
  },
  beforeMount() {
    this.getCurrentDayOrders();
    this.getStore();
    this.$store.dispatch("orders/getOpenDayFile");
    this.getClosedDates().then(() => {
      this.changeDateToToday();
    });
  },
  created() {
    this.setDriverDeliveryFee();
  },
  watch: {
    openDrivers() {
      return this.$store.state.orders.openDrivers;
    },
    assignedRoute: function () {
      if (this.selectedDriver) {
        return this.$store.getters["orders/assignedOrdersByDriverId"](
          this.selectedDriver.driver_id
        );
      }
    },
    selectedDriver() {
      return this.$store.getters["drivers/selectedDriver"];
    },
    getIsOpenCloseAllDriverModalStatus: function () {
      return this.$store.getters["orders/getIsOpenCloseAllDriverModalStatus"];
    },
    onDutyDrivers: function () {
      return this.$store.getters["drivers/onDutyDrivers"];
    },
    startAndEndDate(oldValues, newValues) {
      if (newValues[0] !== oldValues[0] || newValues[1] !== oldValues[1]) {
        this.searchDriverOrders()
        this.getAllDriversPerformance()
        this.fetchTransactions()
        this.searchHistoricalOrders()
      }
    },
    selectedDriversToGetPerformance(){
      this.setPerformanceData()
      this.updateDriversPerformanceChart()
    }
  },
  methods: {
    searchHistoricalOrders: function () {
      if(this.accountReport.startDate && this.accountReport.endDate){
        const payload = {
          storeId: this.store_id,
          startDate: moment(this.accountReport.startDate).format("YYYY-MM-DDTkk:mm"),
          endDate: moment(this.accountReport.endDate).format("YYYY-MM-DDTkk:mm"),
        };

        this.$http
          .post(this.api_url + "Api/V1/Orders/SearchHistoricalOrders", payload)
          .then(
            (res) => {            
              this.historicalOrders = res.body;
            },
            (res) => {
              console.error("Failed to get historical...", res.body);
            }
          );
      }
    },
    setDriverDeliveryFee: function() {
      this.deliveryFee = this.settings.DriverDeliveryFee
    },
    goToAddCreditScreen: function() {
      this.$store.dispatch("app/setSettingsPanelBillingTabComponent", 'credits');
      this.$store.dispatch("app/setSettingsPanelTab", 4);
      this.$store.dispatch("app/setPanel", "settingsPanel");
    },
    getShortOrderNumber: (orderNumber) => orderFunctions.getShortOrderNumber(orderNumber),
    changeTab: function (t) {
      this.tab = t;
      
      if(t == 1){
        this.searchHistoricalOrders();
      }

      if(t == 2){
        this.fetchTransactions();
      }

      if(t == 3){
        this.searchDriverOrders();
      }

      if(t == 4 && !this.storePerfomanceChart && !this.driversPerfomanceChart){
        this.getAllDriversPerformance();
      }
      
      if(t == 4 && this.storePerfomanceChart){
        setTimeout(() => {
          this.createStorePerformanceChart(this.getStorePerformanceChartData());
        }, 500);
      }

      if(t == 4 && this.driversPerfomanceChart){
        setTimeout(() => {
          this.createDriverPerformanceChart(this.getDriversPerformanceChartData());
        }, 500);
      }
    },
    getAllDriversPerformance: function(){
      const driverIds = this.drivers.map(driver => driver.driver_id);
      const body = {
        storeId: this.$store.getters["getStoreID"],
        driverIds: driverIds,
        startDate: this.accountReport.startDate ?? this.currentDay.startDate,
        endDate: this.accountReport.endDate ?? this.currentDay.endDate
      };

      this.$http
        .post(this.api_url + "Api/V1/Drivers/GetDriversPerformance", body)
        .then(
          (res) => {
            this.driversPerfomanceData = res.body;
            if(!this.storePerfomanceChart && !this.driversPerfomanceChart){
              this.setPerformanceData();
              this.createDriverPerformanceChart(this.getDriversPerformanceChartData());
              this.createStorePerformanceChart(this.getStorePerformanceChartData());
            } else{
              this.setPerformanceData()
              this.updateDriversPerformanceChart()
              this.updateStorePerformanceChart()
            }
          },
          (res) => {
            console.log("Failed to get drivers performances...", res.body);
          }
        );
    },
    setPerformanceData: function(){
      const selectedDrivers = this.selectedDriversToGetPerformance.length > 0 ? this.selectedDriversToGetPerformance : this.drivers;
      const driverIds = selectedDrivers.map(driver => driver.driver_id);

      this.sumDeliveredOnTime = 0;
      this.sumEarly = 0;
      this.sumLate = 0;
      this.sumEtaMet = 0;
      this.sumDelivered = 0;
      this.sumReturned = 0;

      this.storePerformanceSumDeliveredOnTime = 0;
      this.storePerformanceSumEarly = 0;
      this.storePerformanceSumLate = 0;
      this.storePerformanceSumEtaMet = 0;
      this.storePerformanceSumDelivered = 0;
      this.storePerformanceSumReturned = 0;

      this.driversPerfomanceData.forEach(driver => {
          if(driverIds.includes(driver.driverId)){
            this.sumDeliveredOnTime += driver.numberOfOrdersDeliveredOnTime;
            this.sumEarly += driver.numberOfOrdersEarly;
            this.sumLate += driver.numberOfOrdersLate;
            this.sumEtaMet += driver.numberOfOrdersWithMetETA;
            this.sumDelivered += driver.numberOfDeliveredOrders;
            this.sumReturned += driver.numberOfReturnedOrders
          }
          this.storePerformanceSumDeliveredOnTime += driver.numberOfOrdersDeliveredOnTime;
          this.storePerformanceSumEarly += driver.numberOfOrdersEarly;
          this.storePerformanceSumLate += driver.numberOfOrdersLate;
          this.storePerformanceSumEtaMet += driver.numberOfOrdersWithMetETA;
          this.storePerformanceSumDelivered += driver.numberOfDeliveredOrders;
          this.storePerformanceSumReturned += driver.numberOfReturnedOrders
      });

    },
    getStorePerformanceChartData: function(){
      const data = {
        datasets: [{
          data: [
            this.storePerformanceSumEarly,
            this.storePerformanceSumDeliveredOnTime,
            this.storePerformanceSumLate,
          ],
          backgroundColor: ['#37c936', '#f1af46', '#f14668'],
        }],
      };

      return data;
    },
    getDriversPerformanceChartData: function(){
      const data = {
        datasets: [{
          data: [
            this.sumEarly,
            this.sumDeliveredOnTime,
            this.sumLate,
          ],
          backgroundColor: ['#37c936', '#f1af46', '#f14668'],
        }],
      };

      return data;
    },
    updateStorePerformanceChart: function () {
      this.storePerfomanceChart.data = this.getStorePerformanceChartData();
      this.storePerfomanceChart.update();
    },
    updateDriversPerformanceChart: function () {
      this.driversPerfomanceChart.data = this.getDriversPerformanceChartData();
      this.driversPerfomanceChart.update();
    },
    createDriverPerformanceChart: function (data) {
      if(this.$refs.driversPerfomanceChart){
        const ctx = this.$refs.driversPerfomanceChart.getContext('2d');
        this.driversPerfomanceChart = new Chart(ctx, {
            type: 'doughnut',
            data: data,
          });
      }
    },
    createStorePerformanceChart: function (data) {
      if(this.$refs.storePerfomanceChart){
        const ctx = this.$refs.storePerfomanceChart.getContext('2d');
        this.storePerfomanceChart = new Chart(ctx, {
            type: 'doughnut',
            data: data,
          });
      }
    },
    changeDateToToday: function () {
      this.accountReport.startDate = null;
      this.accountReport.endDate = null;

      if (this.openDayFile && this.openDayFile.openDateTime) {
        const openDate = new Date(this.openDayFile.openDateTime);
        this.currentDay.startDate = moment(this.formatDateTime(openDate, true)).format(
          "YYYY-MM-DDTkk:mm"
        );
      } else {
        if (this.closedDates?.length > 0) {
          this.currentDay.startDate = moment(this.closedDates[0].closeDateTime).format(
            "YYYY-MM-DDTkk:mm"
          );
        } else {
          this.currentDay.startDate = moment(
            this.formatDateTime(
              new Date(Date.now() - 1000 * 60 * 60 * 24 * 30),
              true
            )
          ).format("YYYY-MM-DDTkk:mm");
        }
      }

      this.currentDay.endDate = moment().format("YYYY-MM-DDTHH:mm");
      this.getClosedDates()
      this.searchDriverOrders();
      this.getAllDriversPerformance();
      this.fetchTransactions()
    },
    onRowClick(i){
      this.$confirm("You are attempting to export this row. Confirm to proceed!").then(() => {
        let p = this.payRollReportOrders[i];
        let dataToExport = [];

        dataToExport.push({
          "DRIVER": p?.driverName || "",
          "# ORDERS": (p?.orders || []).length,
          "TIPS": "$"  + p.tips,
          "AVG. TIP TOTAL": "$" + p.averageTipTotal,
          "FEES": "$" + p.fees,
          "": ""
        });

        //spacing
        dataToExport.push({
          "DRIVER": "",
          "# ORDERS": "",
          "TIPS": "",
          "AVG. TIP TOTAL": "",
          "FEES": "",
          "": ""
        });

        //header
        dataToExport.push({
          "DRIVER": "DATE",
          "# ORDERS": "ORDER #",
          "TIPS": "TOTAL",
          "AVG. TIP TOTAL": "PMT",
          "FEES": "TIP",
          "": "FEE"
        });

        (p?.orders || []).forEach(o => {
          dataToExport.push({
            "DRIVER": o.entryTime,
            "# ORDERS": o.orderNumber,
            "TIPS": o.total,
            "AVG. TIP TOTAL": o.payment,
            "FEES": "$" + o.tip,
            "": "$" + o.driverDeliveryFee
          });
        })

        excelParser().exportDataFromJSON(dataToExport, null, null);


      });
    },
    formatDateTime: (d, withTime = false) => timeFunctions.formatDateTime(d, withTime),
    onCloseDateChange(event){
      this.selectedDayId = event.target.value;
      const chosenDay = this.closedDates.find(d => d.dayId == event.target.value);
      this.accountReport.startDate = moment(chosenDay.openDateTime).format("YYYY-MM-DDTkk:mm");
      this.accountReport.endDate = moment(chosenDay.closeDateTime).format("YYYY-MM-DDTkk:mm");
    },
    getClosedDates: function () {
        const api_url = this.$store.getters["orders/getApiUrl"];
        // Return the promise generated by Vue.http.get() for chaining
        return Vue.http.get(api_url + 'Api/V1/Stores/GetClosedDayFiles?storeId=' + this.store_id)
          .then(res => {
            if (res.body == null) {
              alert(res.body.error)
            } else {
              this.closedDates = res.body;
            }
          })
          .catch(error => {
            console.log('Failed to get open day file...', error);
          });
      },
    exportData() {

      let dataToExport = [];
      let filters = {
        "Selected Date": this.selectedDayId,
        "From": this.accountReport.startDate ?? this.currentDay.startDate,
        "To": this.accountReport.endDate ?? this.currentDay.endDate,
        " ": "",
        "  ": ""
      }
      dataToExport.push(filters);

      //spacing line
      dataToExport.push({
        "Selected Date": "",
        "From": "",
        "To": "",
        " ": "",
        "  ": ""
      });


      let infoHeaders = {
        "Selected Date": "DRIVER",
        "From": "# ORDERS",
        "To": "TIPS",
        " ": "AVG. TIP TOTAL",
        "  ": "FEES"
      }
      dataToExport.push(infoHeaders);

      this.payRollReportOrders.forEach(p => {
        let data = {
          "Selected Date": p.driverName,
          "From": p.orders.length,
          "To":  "$"  + p.tips,
          " ":  "$"  + p.averageTipTotal,
          "  ": p.fees,
        }
        dataToExport.push(data);
      });

      let totalRow = {
        "Selected Date": "TOTALS:",
        "From": this.payrollReportTotals.totalOrders,
        "To": "$"  + this.payrollReportTotals.totalTips,
        " ": "$" + this.payrollReportTotals.totalAverageTipTotal,
        "  ": this.payrollReportTotals.totalFees,
      };

      dataToExport.push(totalRow);

      excelParser().exportDataFromJSON(dataToExport, "TIP_REPORT", null);
    },
    isValidNumber(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9]+$/.test(char) || /^\.+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    input(event) {
      if (event.target.value < this.minLength) {
        alert("abc");
      }
    },
    //set panel
    setPanel: function (panel) {
      this.$store.dispatch("app/setPanel", panel);
    },
    closeDriverAccountsModal: function () {
      this.$store.state.orders.openDriversModalStatus = false;
    },
    //Open close driver modal pop-up
    openCloseDayModal: function () {
      if (this.assignedRoute.length) {
        alert("Assigned orders must be delivered before the close all");
      } else {
        this.newDriverModalStatus = true;
      }
    },
    //open Close All Driver Modal
    openCloseAllDriverModal: function () {
      var isAssignedOrdersExists = false;
      var driversName = "";
      this.onDutyDrivers.forEach((d) => {
        var orders = this.$store.getters["orders/assignedOrdersByDriverId"](
          d.driver_id
        );
        if (orders.length > 0) {
          driversName = driversName + " , " + d.driver_name + (d.DriverLastName != null ? " " + d.DriverLastName : "");
          isAssignedOrdersExists = true;
        }
      });
      if (!isAssignedOrdersExists) {
        this.$store.state.orders.isOpenCloseAllDriverModal = true;
      } else {
        alert(
          "Assigned orders to the drivers" +
            driversName +
            " must be delivered before the close driver"
        );
      }
    },
    //Close close driver modal pop-up
    toggleCloseModal: function () {
      this.newDriverModalStatus = !this.newDriverModalStatus;
    },
    //Close close driver modal pop-up
    closeDriverReportModal: function () {
      this.driverReportModalStatus = !this.driverReportModalStatus;
    },
    //close Open Drivers Modal Status
    closeOpenDriversModalStatus: function () {
      //this.openDriversModalStatus = !this.openDriversModalStatus;
      this.$store.state.orders.openDriversModalStatus = false;
    },
    //cancel Close All Driver Modal
    cancelCloseAllDriverModal: function () {
      this.$store.state.orders.isOpenCloseAllDriverModal = false;
    },
    //Open driver payroll modal pop-up
    openDriverPayrollModal: function () {
      this.driverPayrollModalStatus = true;
    },
    //Close close driver payroll pop-up
    closeDriverPayrollModal: function () {
      this.driverPayrollModalStatus = !this.driverPayrollModalStatus;
    },
    // get CurrentDay Orders
    getCurrentDayOrders: function () {
      var storeId = this.$store.getters["getStoreID"];
      var par = {
        driverId: this.selectedDriver?.driver_id,
        storeId: storeId,
      };
      this.$store.dispatch("orders/getCurrentDayOrders", par);
    },
    getStore: function () {
      this.$store.dispatch("orders/getStore");
    },
    // Add / update tip of order
    checkSpecialKeys: function (e, orderId, tip) {
      var payload = {
        id: parseInt(orderId),
        storeId: this.$store.getters["getStoreID"],
        tip: parseFloat(tip),
      };
      this.$store.dispatch("orders/editOrderTip", payload);
      return true;
    },
    //Update store transaction on close day button
    closeStore: function () {
      if (
        confirm(
          "You are about to close the account for the day, press OK to proceed or Cancel to go back, this action cannot be undone."
        ) == true
      ) {
        var par = {
          transactionType: "CloseDay",
          amount: 0,
        };
        this.$store.dispatch("orders/closeStore", par);
      }
    },
    //Update driver transaction on close driver button
    updateDriverTranscation: function (isUseActualTip) {
      var drivers = this.$store.getters["drivers/selectedDriver"];
      var par = {
        amount: this.newTranscation.amount,
        driverId: drivers.driver_id,
        isUseActualTip: isUseActualTip,
        transactionType: "CloseDriver",
      };
      this.$store.dispatch("drivers/saveNewDriverTransaction", par);
      this.newDriverModalStatus = !this.newDriverModalStatus;
      this.setPanel("accountPanel");
    },
    //collapse expand driver order list in the payroll report
    collapseExpandOrderList: function (element) {
      var row = element.target.parentElement.parentElement.parentElement;
      var target_row = row.parentElement.children[row.rowIndex];
      if (target_row.classList.contains("is-hidden")) {
        element.target.classList.add("fa-angle-up");
        element.target.classList.remove("fa-angle-down");
        target_row.classList.remove("is-hidden");
      } else {
        element.target.classList.add("fa-angle-down");
        element.target.classList.remove("fa-angle-up");
        target_row.classList.add("is-hidden");
      }
    },
    //close Close All Driver Modal
    closeCloseAllDriverModal: function () {
      this.$store.state.orders.isOpenCloseAllDriverModal = false;
    },
    //get historical orders
    searchDriverOrders: function () {
      var storeId = this.$store.getters["getStoreID"];
      // First check for the pattern
      var par = {
        storeId: storeId,
        startDate: this.accountReport.startDate ?? this.currentDay.startDate,
        endDate: this.accountReport.endDate ?? this.currentDay.endDate,
      };
      this.$http
        .post(this.api_url + "Api/V1/Orders/SearchDriverOrders", par)
        .then(
          (res) => {
            this.payRollReportOrders = res.body;

            const totalOrders = this.payRollReportOrders.reduce(
              (accumulator, orders) => {
                return accumulator + orders.orderCount;
              },
              0
            );
            const totalTips = this.payRollReportOrders.reduce(
              (accumulator, orders) => {
                return accumulator + orders.tips;
              },
              0
            );
            const totalAverageTipTotal = this.payRollReportOrders.reduce(
              (accumulator, orders) => {
                return accumulator + orders.averageTipTotal;
              },
              0
            );
            const totalFees = this.payRollReportOrders.reduce(
              (accumulator, orders) => {
                return accumulator + orders.fees;
              },
              0
            );

            this.payrollReportTotals = {
              totalOrders,
              totalTips,
              totalAverageTipTotal,
              totalFees,
            };
          },
          (res) => {
            console.log("Failed to update driver...", res.body);
          }
        );
    },
    getOrderSourceLogo: (source) => orderFunctions.getOrderSourceLogo(source),
    getShortOrderNumber: (orderNumber) =>
      orderFunctions.getShortOrderNumber(orderNumber),
    getSourceLogo: function (source) {
      if (!source || source.toLowerCase().trim().includes("ontime")) {
        return "/img/orders/Ontimeminilogo.png";
      } else if (source.toLowerCase().trim().includes("uber")) {
        return "/img/orders/Uberats.png";
      } else if (source.toLowerCase().trim().includes("doordash")) {
        return "/img/orders/doordash-logo2.png";
      } else if (source.toLowerCase().trim().includes("grubhub")) {
        return "/img/orders/grubhub2png.png";
      } else if (source.toLowerCase().trim().includes("square")) {
        return "/img/orders/square2.png";
      } else if (source.toLowerCase().trim().includes("flipdish")) {
        return "/img/orders/flip2.png";
      }
      return false;
    },
    fetchTransactions() {
        const body = {
          storeId: this.$store.getters["getStoreID"],
          startDate: this.accountReport.startDate ?? this.currentDay.startDate,
          endDate: this.accountReport.endDate ?? this.currentDay.endDate
        };

        this.$http
            .post(this.api_url + `Api/V1/Stores/GetBillingTransactionsByDate`, body)
            .then(
                (res) => {
                    this.transactions = res.body;
                    this.transactions = this.transactions.reverse()
                    
                    this.ordersRequested = 0;
                    this.creditsBought = 0;
                    this.feesPaid = 0;
                    this.ordersIdFetchedForBilling = []
                    
                    const canceledOrderIds = this.transactions.filter(t => t.transactionType === "CancelingOutsourcedOrder")
                                              .map(t => t.orderId);
                    // GET VALUES
                    this.transactions.forEach((t) => {
                      if (t.transactionType === 'Outsourced' && !canceledOrderIds.includes(t.orderId)) {
                        this.ordersRequested += 1;
                        this.feesPaid += t.amount;
                      } else {
                        this.creditsBought += t.amount;
                      }
                      if(t.orderId){
                        (this.ordersIdFetchedForBilling).push(t.orderId);
                      }
                    })

                    this.getOrdersTips()
                },
                (err) => {
                    console.log("Error", err);
                }
            );
    },
    getOrdersTips(){
      const body = {
        storeId: this.$store.getters["getStoreID"],
        orderIds: this.ordersIdFetchedForBilling,
        startDate: this.accountReport.startDate ?? this.currentDay.startDate,
        endDate: this.accountReport.endDate ?? this.currentDay.endDate
      }

      this.$http
          .post(this.api_url + `Api/V1/Orders/GetOrdersTips`, body)
          .then(
              (res) => {
                this.collectedTips = 0;
                this.totalTipsPaid = 0;
                res.body.forEach(o => {
                  this.collectedTips += o.tip;
                  this.totalTipsPaid += o.outsourcedTip;
                })
              },
              (err) => {
                  console.log("Error", err);
              }
          );
    },
    getCurrentMonth() {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        return `${year}-${month}`;
    },
    moneyFormat(number) {
      return custom.moneyFormat(number)
    },
  },
};
</script>

<style scoped>

.row-margin {
  margin-bottom: 10px;
}

.padding {
  padding-top: 38px;
}

.overflow {
  overflow: hidden;
}

.report-header {
  width: 100%;
}

table {
  width: 100% !important;
}

.filter-section {
  padding: 1rem;
}

#tblPrepaidOrders .pt-4 {
  padding-top: 2rem;
}

#tblPrepaidOrders td,
#tblPrepaidOrders th {
  border: none;
  text-align: center;
  width: 16.66%;
  padding: 5px;
}

.mt-0 {
  margin-top: 0px !important;
}

.vertical-line {
  border-left: 1px solid #afabab;
  height: 67vh;
}

.tabs li {
    width: 10rem;
}
</style>
<style>
.date-picker-calender {
  position: fixed !important;
}

.input-symbol-euro {
  position: relative;
}

.input-symbol-euro input {
  padding-left: 18px;
}

.input-symbol-euro:before {
  position: absolute;
  top: 3px;
  content: "$";
  left: 29px;
  z-index: 5;
}

.button-right {
  float: right;
}

.table-today-orders-rows > .column {
  padding-bottom: .5rem;
  border-bottom: lightgray solid 1px;
}

.collapse-expand-order-list-icon {
  margin:0;
  padding:1rem;
}

.reports-pane {
  max-height: calc(100vh - 140px);
  overflow-y: auto;
}

.selectWrap, .labelText {
  display: flex;
  align-items: center;
  justify-content: end;
}

.selectWrap select {
  background-color: white;
  border: #b5b5b5 1px solid;
  padding: 9px;
  border-radius: 4px;
}

.total-column {
  max-width: 12em;
}
.source-column {
  max-width: 6em;
}

.has-text-right {
  justify-content: end;
}
.is-1-5 {
  max-width: 19em !important;
}
.bold {
  font-weight: bold !important;
}

.status-column {
  max-width: 10rem !important;
}

.credit-report-number-column{
  min-width: 68px;
  max-width: 10.5em !important;
  margin-right: 50px;
  margin-left: 8px;
}

.available-credit-column {
  border: 1px grey solid;
}

.add-credit-column{
  background-color: #3273dc;
  max-width: 6.5rem !important;
  text-align: center;
}

.white-text {
  color: white;
}

.delivery-performance-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.performance-container {
}

.title-box {
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 8px;
    display: flex;
    justify-content: center;
}

#multiselect-driver::placeholder {
    font-weight: bold;
    color: #4a4a4a;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
}

.store-performance-title {
  font-weight: bold;
  margin: 0;
}

.multiselect-container {
  padding: 20px;
}

.donut-chart-container {
  width: 400px;
  padding: 20px;
}

.has-text-center{
  text-align: center !important;
}

td, th {
  text-transform: uppercase;
}

.legend-container {
  display: flex;
}

table {
  border-collapse: collapse;
  width: 50%;
}

td {
  padding: 10px;
}

.bold {
  font-weight: bold;
}

.legend-container{
  margin-top: 3em;
}

.green-rectangle {
  width: 13px;
  height: 13px;
  background-color: #37c936;
  display: inline-block;
  margin-right: 5px;
}

.yellow-rectangle {
  width: 13px;
  height: 13px;
  background-color: #f1af46;
  display: inline-block;
  margin-right: 5px;
}

.red-rectangle {
  width: 13px;
  height: 13px;
  background-color: #f14668;
  display: inline-block;
  margin-right: 5px;
}

.numbers-table{
  margin-right: 2em;
}

.multiselect__tags {
    max-width: 400px !important;
}

.space-report{
  margin-right: 5.6em
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>