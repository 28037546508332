<template>
  <modal
    v-bind:isActive="showPromptModal"
    v-on:close="togglePromptModal()"
    class="prompt-wrap"
  >
    <template v-if="driverAccounts">
      <div class="prompt-header-center" slot="header">
        Store Opened Succesfully
      </div>
      <div class="prompt-body" slot="body">
        <div class="auto-close-day">
          <label>
            <input type="checkbox" v-model="isAutoCloseEnabled" />
            Auto Close Day
          </label>

          <div v-if="isAutoCloseEnabled" class="auto-close-time">
            <div class="select">
              <select v-model="selectedHour" :disabled="!isAutoCloseEnabled">
                <option v-for="hour in hours" :key="hour" :value="hour">
                  {{ hour }}
                </option>
              </select>
            </div>
            <div class="select">
              <select v-model="selectedMinute" :disabled="!isAutoCloseEnabled">
                <option v-for="minute in minutes" :key="minute" :value="minute">
                  {{ minute }}
                </option>
              </select>
            </div>
            <div class="select">
              <select v-model="selectedPeriod" :disabled="!isAutoCloseEnabled">
                <option v-for="period in periods" :key="period" :value="period">
                  {{ period }}
                </option>
              </select>
            </div>
          </div>

          <p class="explanation">
            When activated, the day will be closed automatically at the time
            selected, drivers will be returned automatically, and any
            undelivered orders will be marked delivered
          </p>
        </div>
      </div>
      <div class="prompt-footer" slot="footer" v-if="driverAccounts">
        <a class="button mr-1" v-on:click="confirmPrompt()">Ok</a>
        <a class="button is-danger" v-on:click="togglePromptModal()">Skip</a>
      </div>
    </template>

    <template v-else>
      <div class="prompt-header" slot="header">Store Alert</div>
      <div class="prompt-body" slot="body">Day Opened Successfully</div>
      <div class="prompt-footer" slot="footer">
        <a class="button mr-1" v-on:click="togglePromptModal()">Ok</a>
      </div>
    </template>
  </modal>
</template>

<script>
var modal = require("../modal.vue").default;

module.exports = {
  components: { modal },
  data: function () {
    return {
      storeId: this.$store.state.user.store_id,
      apiUrl: this.$store.state.api_url,
      isAutoCloseEnabled: false,
      selectedHour: "05",
      selectedMinute: "00",
      selectedPeriod: "AM",
      hours: [...Array(12).keys()].map((i) => String(i + 1).padStart(2, "0")),
      minutes: [...Array(60).keys()].map((i) => String(i).padStart(2, "0")),
      periods: ["AM", "PM"],
    };
  },
  created: function () {},
  computed: {
    showPromptModal: function () {
      return (
        this.$store.state.app.modalType == "DAY_OPEN" &&
        this.$store.state.app.showPromptModal
      );
    },
    driverAccounts: function () {
      return this.$store.state.settings;
    },
  },
  methods: {
    togglePromptModal: function () {
      this.$store.dispatch("app/togglePromptModal");
    },
    confirmPrompt: function () {
      if (!this.isAutoCloseEnabled) {
        this.togglePromptModal();
      } else {
        const payload = {
          storeId: this.storeId,
          closeDate: this.getCloseDate(),
        };

        this.$http
          .post(this.apiUrl + `Api/V1/Stores/AddStoreCloseSchedule`, payload)
          .then(
            (res) => {
              this.togglePromptModal();
            },
            (err) => {
              console.log("Error", err);
            }
          );
      }
    },
    getCloseDate() {
      const hour24 =
        this.selectedPeriod === "AM"
          ? this.selectedHour === "12"
            ? 0
            : parseInt(this.selectedHour)
          : this.selectedHour === "12"
          ? 12
          : parseInt(this.selectedHour) + 12;

      const date = new Date();
      date.setHours(hour24, parseInt(this.selectedMinute), 0, 0);

      const offset = date.getTimezoneOffset();
      date.setMinutes(date.getMinutes() - offset);

      return date.toISOString();
    },
  },
};
</script>

<style scoped>
.prompt-header-center {
  font-size: 2rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
}
.prompt-header {
  font-size: 2rem;
  font-weight: 600;
}

.prompt-body {
  font-size: 1.3rem;
  text-align: center;
}

.prompt-footer {
  width: 100%;
}
.prompt-footer > a {
  float: right;
}

.prompt-footer > a.is-danger {
  margin-right: 1em;
}

.prompt-footer > a.mr-1 {
  margin-right: 1em;
}

.auto-close-day {
  margin: 20px;
}

.auto-close-day label {
  font-weight: bold;
  margin-bottom: 10px;
}

.auto-close-day select {
  margin: 0 5px;
}

.explanation {
  font-size: 0.8em;
  color: #666;
  font-style: italic;
  margin-top: 10px;
}

.auto-close-time {
  margin-top: 5px;
}

.body-header {
  font-size: 35px;
  font-weight: bold;
}
</style>
