<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for -->
  <!-- eslint-disable vue/no-confusing-v-for-v-if -->
  <div id="main-panel-wrapper" class="card">
    <header class="card-header">
    </header>
    <div class="card-content order-panel">
      <!-- Tabs -->
      <div class="tabs is-centered is-toggle is-small is-fullwidth">
        <ul>
          <li v-bind:class="{ 'is-active': tab == 0}">
            <a v-on:click="changeTab(0)">ALL ORDERS</a>
          </li>
          <li v-bind:class="{ 'is-active': tab == 1 }">
            <a v-on:click="changeTab(1)">
              <div class="icon-text">
                <span v-if="orders[6].length" class="icon has-text-danger">
                  <i class="fa fa-exclamation-triangle"></i>
                </span>
                <span>PENDING ({{ orders[6].length }})</span>
              </div>
            </a>
          </li>
          <!-- <li v-bind:class="{ 'is-active': tab == 2 }">
            <a v-on:click="changeTab(2)">PICK UP</a>
          </li> -->
          <li v-if="!autoRouteDrivers" v-bind:class="{ 'is-active': tab == 3 }">
            <a v-on:click="changeTab(3)">DELIVERY</a>
          </li>
          <li v-if="autoRouteDrivers" v-bind:class="{ 'is-active': tab == 4 }">
            <a v-on:click="changeTab(4)">DELIVERY</a>
          </li>
          <!-- <li v-bind:class="{ 'is-active': tab == 5}">
            <a v-on:click="changeTab(5)">OUTSOURCED</a>
          </li> -->
          <li v-bind:class="{ 'is-active': tab == 6 }">
            <a v-on:click="changeTab(6)">ON THE WAY</a>
          </li>
          <li v-bind:class="{ 'is-active': tab == 7 }">
            <a v-on:click="changeTab(7)">DELIVERED</a>
          </li>
          <li v-bind:class="{ 'is-active': tab == 8 }">
            <a v-on:click="changeTab(8)">RETURNS</a>
          </li>
        </ul>
      </div>

      <!-- Panels -->
      <customer-panel v-if="tab == 0"></customer-panel>

      <pending v-if="tab == 1" v-on:changeOrderStatus="changeOrderStatus"
        v-on:toggleOrderSelection="toggleOrderSelection">
      </pending>

      <pickUp 
        v-if="tab == 2"
        v-on:changeOrderStatus="changeOrderStatus"
        v-on:toggleOrderSelection="toggleOrderSelection"
      >
      </pickUp>
      
      <inStore v-if="tab == 3" v-on:dragstart="dragstart" v-on:dragdrop="dragdrop"
        v-on:changeOrderStatus="changeOrderStatus" v-on:toggleOrderSelection="toggleOrderSelection"
        v-on:moveOrderUp="moveOrderUp" v-on:moveOrderDown="moveOrderDown">
      </inStore>

      <route v-if="tab == 4" v-on:dragstart="dragstart" v-on:dragdrop="dragdrop"
        v-on:changeOrderStatus="changeOrderStatus" v-on:toggleOrderSelection="toggleOrderSelection"
        v-on:moveOrderUp="moveOrderUp" v-on:moveOrderDown="moveOrderDown">
      </route>

      <outsourced-report-panel v-if="tab == 5"
        v-on:changeOrderStatus="changeOrderStatus"></outsourced-report-panel>

      <onTheWay v-if="tab == 6" v-on:changeOrderStatus="changeOrderStatus">
      </onTheWay>

      <delivered v-if="(tab == 7)" v-on:remakeOrder="remakeOrder"
        v-on:changeOrderStatus="changeOrderStatus"
        v-on:toggleOrderSelection="toggleOrderSelection">
      </delivered>

      <returns v-if="tab == 8" v-on:changeOrderStatus="changeOrderStatus"
        v-on:toggleOrderSelection="toggleOrderSelection" v-on:remakeOrder="remakeOrder">
      </returns>

    </div>
  </div>
</template>

<script>
  var customerPanel = require('./customer_panel.vue').default;
  var inStore = require("./instore.vue").default;
  var route = require("./route.vue").default;
  var onTheWay = require("./ontheway.vue").default;
  var delivered = require("./delivered.vue").default;
  var returns = require("./returns.vue").default;
  var pending = require("./pending.vue").default;
  var pickUp = require("./pick_up.vue").default;
  var outsourcedReportPanel = require("./outsourced_report.vue").default;

  module.exports = {
    components: {
      customerPanel,
      inStore,
      onTheWay,
      delivered,
      returns,
      pending,
      route,
      pickUp,
      outsourcedReportPanel
    },
    data: function () {
      return {
        base_url: this.$store.state.base_url,
        hub_url: this.$store.state.hub_url,
        store_id: this.$store.state.user.store_id,
        drivers: this.$store.state.drivers.all,
        draggedOrder: {},
        draggedOrigin: "",
        turnOnAutomation: {},
        tab: this.$store.state.settings["auto_route_drivers"] ? 4 : 3,
        routes: [],
      };
    },
    created: function () {
      this.getOrders();
      this.getDrivers();
      this.getStore();
    },
    computed: {
      selectedOrders: function () {
        return this.$store.getters["orders/selectedOrders"];
      },
      autoRouteDrivers: function () {
        return this.$store.state.settings["auto_route_drivers"];
      },
      readyOrders: function () {
        return this.$store.getters["orders/readyOrders"];
      },
      orders: function () {
        return [
          this.$store.getters["orders/inStoreNotNext"], // 0
          this.$store.getters["orders/onTheWayOrders"], // 1
          this.$store.getters["orders/deliveredOrders"], // 2
          this.$store.getters["orders/returnedOrders"], // 3
          this.$store.state.orders.all, // 4
          this.$store.getters["orders/inStoreOrders"], // 5
          (this.$store.getters["orders/pendingOrders"] || []).filter(o => !o?.external_delivery_source), // 6
          this.$store.getters["orders/nextRoute"], // 7
          [
                ...(this.$store.getters["orders/unroutedOrders"] || []),
                ...(this.$store.getters["orders/deliveredOrders"] || [])
            ].filter(o => o.external_delivery_source && o.external_delivery_status === 'pending' || o.external_delivery_source === 'pickup'), //8
        ];
      },
      now: function () {
        return Date.now();
      },
      assignedOrders: function () {
        return this.$store.getters["orders/assignedOrders"];
      },
      onDutyDrivers: function () {
        return this.$store.getters["drivers/onDutyDrivers"];
      },
      nextDriver: function () {
        return this.$store.getters["drivers/nextDriver"];
      },
      nextRoute: function () {
        return this.$store.getters["orders/nextRoute"];
      },
      inStoreNotNext: function () {
        return this.$store.getters["orders/inStoreNotNext"];
      },
      averagePrepTime: function () {
        return this.$store.getters["orders/averagePrepTime"];
      },
      // hold all order statuses that are changing.
      orderStatusChanging: function () {
        return this.$store.getters["orders/orderStatusChanging"];
      },
      storeInfo: function () {
        return this.$store.getters["orders/getStore"]
      }
    },
    watch: {
      autoRouteDrivers() {
        if (this.autoRouteDrivers) {
          this.changeTab(4);
        } else {
          this.changeTab(3);
        }
      },
    },
    filters: {},
    methods: {
      setPanel: function (panel) {
        this.$store.dispatch("app/setPanel", panel);
      },
      getOrders: function (callback) {
        var payload = {
          store_id: this.store_id,
          method: "GET"
        };

        // Create HTTP Request
        this.$http.post(this.base_url + "orders/", payload).then(
          (res) => {
            if (res.body.result != 0) {
              alert(res.body.error);
            }
            // Update Vuex with new orders
            this.$store
              .dispatch("orders/updateOrders", res.body.order_info)
              .then(function () {
                // Execute callback after orders have been updated if callback is a function
                if (typeof callback === "function") {
                  callback();
                }
              });
          },
          (res) => {
            // failure
            console.log("Failed to get orders...", res);
          }
        );
      },
      getRoutes: function (callback) {
        var payload = {
          store_id: this.store_id,
          method: "GET"
        };

        this.$http.post(this.base_url + "routes/", payload).then(
          (res) => {
            if (res.body.result != 0) {
              alert(res.body.error);
            } else {
              this.routes = res.body.routes;
              if (typeof callback === "function") {
                callback();
              }
            }
          },
          (res) => {
            console.log("Failed to get routes...", res);
          }
        );
      },
      moveOrderUp: function (order) {
        var payload = {
          store_id: this.store_id,
          method: "POST",
          order_id: order.order_id,
        };

        this.$http.post(this.base_url + "order/movesooner/", payload).then(
          (res) => { },
          (res) => {
            console.log("Failed to move order up...");
          }
        );
      },
      moveOrderDown: function (order) {
        var payload = {
          store_id: this.store_id,
          method: "POST",
          order_id: order.order_id,
        };

        this.$http.post(this.base_url + "order/movelater/", payload).then(
          (res) => { },
          (res) => {
            console.log("Failed to move order down...");
          }
        );
      },
      remakeOrder: function (order, from_delivered) {
        var payload = {
          store_id: this.store_id,
          method: "POST",
          order_id: order.order_id,
          from_delivered
        };

        this.$http.post(this.base_url + "order/remake/", payload).then(
          (res) => { },
          (res) => {
            console.log("Failed to remake order...");
          }
        );
      },
      toggleOrderSelection: function (order) {
        this.$store.dispatch("orders/toggleOrderSelection", order);
      },
      changeOrderStatus: function (order) {
        this.$store.dispatch("orders/addOrderStatusChanging", order);
        var payload = {
          store_id: this.store_id,
          method: "POST",
          order_id: order.order_id,
        };

        if (!order.is_ready_to_deliver) {
          this.$http.post(this.base_url + "order/ready/", payload).then(
            (res) => {
              if (res.body.result != 0) {
                alert(res.body.error);
              } else {
                this.$store.dispatch("orders/removeOrderStatusChanging", order);
              }
            },
            (res) => {
              console.log("failed to update order");
            }
          );
        } else if (order.is_ready_to_deliver) {
          this.$http.post(this.base_url + "order/notready/", payload).then(
            (res) => {
              this.$store.dispatch("orders/removeOrderStatusChanging", order);
            },
            (res) => {
              console.log("failed to update order");
            }
          );
        }
      },
      changeTab: function (value) {
        this.$store.dispatch("orders/clearSelectedOrders");
        this.tab = value;
      },
      getTTD: function (ETA, ENTRY) {
        if (typeof ETA == "string" && typeof ENTRY == "string") {
          var parsedETA = new Date(parseInt(ETA.substring(6, ETA.length - 2)));
          var parsedENTRY = new Date(
            parseInt(ENTRY.substring(6, ENTRY.length - 2))
          );
          var TTD = parsedETA - parsedENTRY;
          return Math.round(TTD / 60000);
        }
      },
      convertDate: function (value) {
        if (value)
          return new Date(parseInt(value.substring(6, value.length - 2)));
        else return null;
      },
      timeDifference: function (value1, value2) {
        if (value1 && value2) {
          return Math.round((value1.getTime() - value2.getTime()) / 60000);
        } else {
          return null;
        }
      },
      dragstart: function (order, origin) {
        for (var key in order) {
          this.$set(this.draggedOrder, key, order[key]);
        }
        this.draggedOrigin = origin;
        this.$store.dispatch("orders/dragOrder", order);
      },
      dragdrop: function (d_id, loc) {
        // loc designates zone in which it was dropped
        // 0 => dropped into assigned orders zone
        // 1 => dropped into tentative orders zone
        var payload = {
          store_id: this.store_id,
          method: "POST",
          order_id: this.draggedOrder.order_id,
          driver_id: d_id,
        };

        // if order is assigned already, cancel from route
        if (this.draggedOrder.driver_id && loc == 1) {
          payload.driver_id = this.draggedOrder.driver_id;
          this.$http
            .post(this.base_url + "driver/route/order/remove/", payload)
            .then(
              (res) => {
                if (res.body.result != 0) {
                  alert(res.body.error);
                }
              },
              (res) => {
                console.log("Failed to remove assigned order...");
              }
            );
        }
        // Order has not been assigned, assign to route
        else if (loc == 0) {
          // verify that d_id exists
          if (d_id) {
            this.$http
              .post(this.base_url + "driver/route/order/add/", payload)
              .then(
                (res) => {
                  if (res.body.result != 0) {
                    alert(res.body.error);
                  }
                },
                (res) => {
                  console.log("Failed to assign order...");
                }
              );
          }
        }
      },
      getDrivers: function () {
        this.$store.dispatch("drivers/getDrivers");
      },
      getStore: function () {
        this.$store.dispatch("orders/getStore");
      },
    },
  };
</script>

<style scoped>
  .tabs {
    margin-bottom: 1rem !important;
    /* margin-top: 1rem; */
  }

  .card-content{
    padding-top: 0;
  }

  .card-header {
    margin-top: 2px !important;
}
</style>