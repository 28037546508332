<template>
  <div
    id="modal_conf"
    class="modal-wrapper modal"
    v-bind:class="{ 'is-active': isActive }"
  >
    <!-- Bulma: create transparent overlay -->
    <div class="modal-background" v-on:click="toggleModal"></div>

    <!-- Bulma: content must go in modal-background. -->
    <div class="modal-card" v-bind:style="myWidth">
      <section class="modal-card-body">
        <slot name="body">
          <div class="columns" v-if="currentStep == 1">
            <div class="column">
              <h2 class="title">
                Hi, there! Let's set up your store
                <i class="fa fa-hand-wave"></i>
              </h2>
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label"
                      >Full Name <span class="required-span"> *</span></label
                    >
                    <p class="control has-icons-left">
                      <input
                        class="input"
                        type="text"
                        v-model="tempSettings.firstName"
                      />
                      <span class="icon is-small is-left">
                        <i class="fa fa-user"></i>
                      </span>
                    </p>
                    <p class="help is-danger" v-if="emptyFirstName">Required</p>
                  </div>
                </div>
              </div>

              <div class="columns">
                <div class="column is-three-fifths">
                  <div class="field">
                    <label class="label"
                      >Restaurant Name
                      <span class="required-span"> *</span></label
                    >
                    <p class="control has-icons-left">
                      <input
                        class="input"
                        type="text"
                        v-model="tempSettings.storeName"
                      />
                      <span class="icon is-small is-left">
                        <i class="fa fa-home"></i>
                      </span>
                    </p>
                    <p class="help is-danger" v-if="emptyLastName">Required</p>
                  </div>
                </div>
                <div class="column">
                  <div class="field">
                    <label class="label"
                      >Store Phone Number
                      <span class="required-span"> *</span></label
                    >
                    <p class="control has-icons-left">
                      <input
                        class="input"
                        type="text"
                        @keypress="onlyNumber"
                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        placeholder="Phone #"
                        maxlength="10"
                        v-model="tempSettings.phone"
                      />
                      <span class="icon is-small is-left">
                        <i class="fa fa-phone"></i>
                      </span>
                    </p>
                    <p class="help is-danger" v-if="emptyPhone">Required</p>
                    <p class="help is-danger" v-if="!validPhone">
                      Phone number is not valid
                    </p>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label"
                      >Store Address
                      <span class="required-span"> *</span></label
                    >
                    <p class="control">
                      <address-textbox
                        v-model="tempSettings.address"
                        :class="size"
                      ></address-textbox>
                    </p>
                    <p class="help is-danger" v-if="emptyAddress">Required</p>
                  </div>
                </div>
              </div>

              <div class="field">
                <button
                  class="button is-medium is-responsive custombtn is-fullwidth"
                  v-on:click="completeStepOne()"
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          <div v-if="currentStep == 2">
            <div class="columns">
              <div class="column">
                <h2 class="title">
                  Set your preferences for route optimization
                </h2>

                <label class="label"> Zone 1</label>
                <div class="columns internal-columns-p">
                  <div class="column">
                    <div class="fl-l mr-3 width-r">
                      <label class="">Radius </label>
                    </div>
                    <div class="field has-addons fl-l">
                      <p class="control">
                        <input
                          class="input is-small number-field"
                          step="0.1"
                          type="number"
                          min="1"
                          max="99"
                          :disabled="true"
                          v-model="tempSettings.MileOne"
                        />
                      </p>
                      <p class="control">
                        <label class="button is-small is-static">to</label>
                      </p>
                      <p class="control">
                        <input
                          class="input is-small number-field"
                          step="0.1"
                          type="number"
                          min="1"
                          max="99"
                          v-model="tempSettings.MileTwo"
                        />
                      </p>
                      <p class="control">
                        <label class="button is-small is-static">miles</label>
                      </p>
                    </div>
                  </div>
                  <div class="column">
                    <div class="fl-l mr-3 width-within">
                      <label class="width-within">Deliver Within</label>
                    </div>
                    <div class="field has-addons fl-l">
                      <p class="control">
                        <input
                          class="input is-small number-field"
                          type="number"
                          min="1"
                          max="99"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          step="1"
                          v-model="tempSettings.MinuteOne"
                        />
                      </p>
                      <p class="control">
                        <label class="button is-small is-static">minutes</label>
                      </p>
                    </div>
                  </div>
                </div>

                <label class="label"> Zone 2</label>
                <div class="columns internal-columns-p">
                  <div class="column">
                    <div class="fl-l mr-3 width-r">
                      <label class="">Radius</label>
                    </div>
                    <div class="field has-addons fl-l">
                      <p class="control">
                        <input
                          class="input is-small number-field"
                          type="number"
                          step="0.1"
                          min="1"
                          max="99"
                          :disabled="true"
                          v-model="tempSettings.MileTwo"
                        />
                      </p>
                      <p class="control">
                        <label class="button is-small is-static">to</label>
                      </p>
                      <p class="control">
                        <input
                          class="input is-small number-field"
                          type="number"
                          step="0.1"
                          min="1"
                          max="99"
                          v-model="tempSettings.MileThree"
                        />
                      </p>
                      <p class="control">
                        <label class="button is-small is-static">miles</label>
                      </p>
                    </div>
                  </div>
                  <div class="column">
                    <div class="fl-l mr-3 width-within">
                      <label class="">Deliver Within</label>
                    </div>
                    <div class="field has-addons fl-l">
                      <p class="control">
                        <input
                          class="input is-small number-field"
                          type="number"
                          min="1"
                          max="99"
                          step="1"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          v-model="tempSettings.MinuteTwo"
                        />
                      </p>
                      <p class="control">
                        <label class="button is-small is-static">minutes</label>
                      </p>
                    </div>
                  </div>
                </div>

                <label class="label"> Zone 3</label>
                <div class="columns internal-columns-p">
                  <div class="column">
                    <div class="fl-l mr-3 width-r">
                      <label class="">Radius</label>
                    </div>
                    <div class="field has-addons fl-l">
                      <p class="control">
                        <input
                          class="input is-small number-field"
                          type="number"
                          step="0.1"
                          min="1"
                          max="99"
                          :disabled="true"
                          v-model="tempSettings.MileThree"
                        />
                      </p>
                      <p class="control">
                        <label class="button is-small is-static">+</label>
                      </p>
                    </div>
                  </div>
                  <div class="column">
                    <div class="fl-l mr-3 width-within">
                      <label class="">Deliver Within</label>
                    </div>
                    <div class="field has-addons fl-l">
                      <p class="control">
                        <input
                          class="input is-small number-field"
                          type="number"
                          min="1"
                          max="99"
                          step="1"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          v-model="tempSettings.MinuteThree"
                        />
                      </p>
                      <p class="control">
                        <label class="button is-small is-static">minutes</label>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="columns internal-columns-p opt-top">
                  <div class="column">
                    <div class="fl-l mr-3">
                      <label class="">Order Prep Time</label>
                    </div>
                    <div class="field has-addons fl-l">
                      <p class="control">
                        <input
                          class="input is-small number-field"
                          type="number"
                          min="1"
                          max="99"
                          step="1"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          v-model="tempSettings.AverageOrderPrepTime"
                        />
                      </p>
                      <p class="control">
                        <label class="button is-small is-static">minutes</label>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="columns">
                  <div class="column">
                    <div class="field s2-btn-m-t">
                      <button
                        class="
                          button
                          is-medium is-responsive
                          custombtn
                          is-fullwidth
                        "
                        v-on:click="backToStepOne()"
                      >
                        Back
                      </button>
                    </div>
                  </div>
                  <div class="column">
                    <div class="field s2-btn-m-t">
                      <button
                        class="
                          button
                          is-medium is-responsive
                          custombtn
                          is-fullwidth
                        "
                        v-on:click="completeStepTwo()"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="currentStep == 3">
            <div class="columns">
              <div class="column">
                <h2 class="title driver-h2">
                  Let's create your driver profile
                </h2>
                <h4 class="driver-subtitle">
                  You'll receive an SMS with the link to download the app
                </h4>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label"
                    >First Name <span class="required-span"> *</span></label
                  >
                  <p class="control has-icons-left">
                    <input
                      class="input"
                      type="text"
                      v-model="driver.firstName"
                    />
                    <span class="icon is-small is-left">
                      <i class="fa fa-user"></i>
                    </span>
                  </p>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label"
                    >Last Name <span class="required-span"> *</span></label
                  >
                  <p class="control has-icons-left">
                    <input
                      class="input"
                      type="text"
                      v-model="driver.lastName"
                    />
                    <span class="icon is-small is-left">
                      <i class="fa fa-user"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label"
                    >Cell Phone <span class="required-span"> *</span></label
                  >
                  <p class="control has-icons-left">
                    <input
                      class="input"
                      type="text"
                      @keypress="onlyNumber"
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      maxlength="10"
                      v-model="tempSettings.DriverPhone"
                    />
                    <span class="icon is-small is-left">
                      <i class="fa fa-phone"></i>
                    </span>
                  </p>
                  <p class="help is-danger" v-if="!validPhone">
                    Phone number is not valid
                  </p>
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label"
                    >Pin Number <span class="required-span"> *</span></label
                  >
                  <p class="control has-icons-left">
                    <input
                      class="input key"
                      type="number"
                      maxlength="5"
                      v-model="tempSettings.DriverPin"
                    />
                    <span class="icon is-small is-left">
                      <i class="fa fa-lock"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label"
                    >Confirm pin Number
                    <span class="required-span"> *</span></label
                  >
                  <p class="control has-icons-left">
                    <input
                      class="input key"
                      type="number"
                      maxlength="5"
                      v-model="tempSettings.DriverConfirmPin"
                      @change="verifyPin()"
                    />
                    <span class="icon is-small is-left">
                      <i class="fa fa-lock"></i>
                    </span>
                  </p>
                  <p
                    class="help is-danger"
                    v-bind:style="{
                      visibility: !pinConfirm ? 'visible' : 'hidden',
                    }"
                  >
                    Pin is not Matching
                  </p>
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <div class="field s2-btn-m-t">
                  <button
                    class="
                      button
                      is-medium is-responsive
                      custombtn
                      is-fullwidth
                    "
                    v-on:click="backToStepTwo()"
                  >
                    Back
                  </button>
                </div>
              </div>
              <div class="column">
                <div class="field s2-btn-m-t">
                  <button
                    class="
                      button
                      is-medium is-responsive
                      custombtn
                      is-fullwidth
                    "
                    v-on:click="getStarted()"
                  >
                    Get Started
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="currentStep == 4">
            <div class="columns">
              <div class="column">
                <h2 class="title center">Check Your Phone</h2>
                <p class="otp-p">
                  Enter 6 digit code that we sent to you on your registered
                  phone number
                </p>
                <div class="field">
                  <label class="label"
                    >Enter OTP <span class="required-span"> *</span></label
                  >
                  <p class="control has-icons-left">
                    <input
                      class="input"
                      type="number"
                      maxlength="4"
                      v-model="otp"
                    />
                    <span class="icon is-small is-left">
                      <i class="fa fa-lock"></i>
                    </span>
                  </p>
                </div>
                <p class="required-span mb-2" v-if="response.show">
                  {{ response.message }}
                </p>

                <button
                  class="button is-medium is-responsive custombtn is-fullwidth"
                  v-on:click="verifyOtp()"
                >
                  Verify OTP
                </button>
                <a is-centered v-on:click="resendOtp()"
                  >I didn't get a text message</a
                >
              </div>
            </div>
          </div>

          <div v-if="currentStep == 5">
            <div class="columns">
              <div class="column">
                <h2 class="title">Connect Ontime with your Square POS</h2>
                <div>
                  <span class="squareup"></span>
                </div>

                <div class="field">
                  <strong
                    ><label class="is-small"
                      >Integration:
                      {{
                        squareInProgressEnabled
                          ? "Pending"
                          : tempSettings.square_authorization_token_exists
                          ? "Enabled"
                          : "Disabled"
                      }}</label
                    ></strong
                  >
                </div>

                <div v-if="squareInProgressEnabled">
                  Square Integration Status Pending. Reopen settings after
                  authenticating to complete setup.
                </div>

                <div
                  v-if="
                    !tempSettings.square_authorization_token_exists &&
                    !squareInProgressEnabled
                  "
                  class="field"
                >
                  <a
                    class="button is-primary is-small"
                    :href="squareAppUrl"
                    @click="squareInProgressEnabled = true"
                    >Connect with Square</a
                  >
                </div>

                <a class="is-small position-skip" @click="skip()"
                  >Skip this step</a
                >
              </div>
            </div>
          </div>
        </slot>
      </section>
    </div>
  </div>
</template>
    
    <script>
// Load Components
var addressTextbox = require("./address_textbox_generic.vue").default;
module.exports = {
  components: {
    addressTextbox,
  },
  data: function () {
    return {
      modalWidth: {
        width: "50vw",
      },
      driver_base_url: this.$store.state.drivers.base_url,
      base_url: this.$store.state.base_url,
      api_url: this.$store.state.api_url,
      store_id: this.$store.state.user.store_id,
      tempSettings: {
        firstName: "",
        lastName: "",
        storeName: "",
        phone: "",
        address: "",
        AverageOrderPrepTime: 15,
        hasDriverSettings: false,
        MinuteOne: 40,
        MinuteTwo: 45,
        MinuteThree: 50,
        MileOne: 0,
        MileTwo: 1,
        MileThree: 2,
        DriverName: "",
        DriverPhone: "",
        DriverPin: "",
        DriverConfirmPin: "",
        square_authorization_token_exists: false,
      },
      validation: {
        firstName: false,
        lastName: false,
        storeName: false,
        phone: false,
        address: false,
        otp: false,
      },
      response: {
        show: false,
        message: "",
      },
      driver: {
        firstName: "",
        lastName: "",
      },
      emptyStoreName: false,
      emptyFirstName: false,
      emptyLastName: false,
      emptyPhone: false,
      emptyAddress: false,
      validPhone: true,
      validEmail: true,
      pinConfirm: true,
      currentStep: 1,
      store: {},
      stepValidation: true,
      squareAppUrl: "",
      squareInProgressEnabled: false,
      squareInProgressDisabled: false,
      squareAppUrl: "",
      squareRevokeUrl: "",
      squareRefreshUrl: "",
      isActive: true,
      otp: 0,
      validOtp: true,
      size: "",
    };
  },
  props: {
    myWidth: {
      default: function () {
        return {
          width: 50 + "vw",
        };
      },
    },
  },
  computed: {},
  created: function () {},
  methods: {
    toggleModal: function () {
      this.$emit("close");
    },
    completeStepOne: function () {
      this.stepValidation = true;
      if (!this.tempSettings.firstName) {
        this.emptyFirstName = true;
        this.stepValidation = false;
      } else {
        this.validation.firstName = true;
        this.emptyFirstName = false;
      }
      if (!this.tempSettings.phone) {
        this.emptyPhone = true;
        this.stepValidation = false;
      } else if (!this.isPhoneValid(this.tempSettings.phone)) {
        this.stepValidation = false;
      } else {
        this.validation.phone = true;
        this.emptyPhone = false;
      }

      if (!this.tempSettings.address) {
        this.emptyAddress = true;
        this.stepValidation = false;
      } else {
        this.validation.address = true;
        this.emptyAddress = false;
      }

      if (!this.tempSettings.storeName) {
        this.emptyStoreName = true;
        this.stepValidation = false;
      } else {
        this.validation.storeName = true;
        this.emptyStoreName = false;
      }

      if (this.stepValidation) {
        this.currentStep = 2;
        this.validPhone = true;
      }
    },
    completeStepTwo: function () {
      this.currentStep = 3;
    },
    completeStepThree: function () {
      this.currentStep = 4;
    },
    changeStep: function (val) {
      if (val == "back") {
        if (this.currentStep == 3) {
          this.currentStep = 2;
        } else if (this.currentStep == 2) {
          this.currentStep = 1;
        }
      }

      if (val == "next") {
        if (this.currentStep == 1) {
          this.currentStep = 2;
        } else if (this.currentStep == 2) {
          this.currentStep = 3;
        }
      }
    },
    skipConfig: function () {
      this.configRequired = false;
    },
    getStarted: function () {
      if (!this.pinConfirm) {
        return false;
      }
      var payload = {
        StoreId: parseInt(this.store_id),
        method: "POST",
      };

      this.tempSettings.DriverName =
        this.driver.firstName + " " + this.driver.lastName;
      // Load settings into payloaod
      for (var s in this.tempSettings) {
        payload[s] = this.tempSettings[s];
      }
      this.$http
        .post(this.api_url + "Api/V1/Users/CompleteOnboard/", payload)
        .then(
          (res) => {
            if (res.ok) {
              this.getSettings();
              this.getStore();
              this.getDrivers();
              setTimeout(() => {
                this.currentStep = 4;
                this.isActive = true;
              }, 1000);
            }
          },
          (res) => {
            console.log("Failed to complete onboard process", res);
          }
        );
    },
    getDrivers: function () {
      this.$store.dispatch("drivers/getDrivers");
    },
    getStore: function () {
      this.$store.dispatch("orders/getStoreById");
    },
    getSettings: function () {
      var payload = {
        store_id: this.store_id,
        method: "GET",
      };

      // Load settings into settings
      this.$http.post(this.base_url + "settings/", payload).then(
        (res) => {
          this.$store
            .dispatch("updateSettings", res.body.settings[0])
            .then(() => {
              if (res.body.result != 0) {
                alert(res.body.error);
              }
              // this.square_locations = res.body.square_locations;
              this.squareAppUrl = res.body.squareAppUrl;
              this.squareRevokeUrl = res.body.squareRevokeUrl;
              this.squareRefreshUrl = res.body.squareRefreshUrl;
            });
        },
        (res) => {
          console.log("Failed to get settings...", res);
        }
      );
    },
    skip: function () {
      this.isActive = false;
    },
    // //is phone valid
    isPhoneValid: function (phone) {
      var re = /^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/;
      return re.test(phone);
    },
    isEmailValid: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    verifyPin: function () {
      if (this.tempSettings.DriverPin != this.tempSettings.DriverConfirmPin) {
        this.pinConfirm = false;
      } else {
        this.pinConfirm = true;
      }
    },
    backToStepOne: function () {
      this.currentStep = 1;
    },
    backToStepTwo: function () {
      this.currentStep = 2;
    },
    saveNewDriver: function () {
      this.validationErrorPhoneNumber = false;
      if (
        !this.newDriver.phone_number ||
        this.newDriver.phone_number.length != 10
      ) {
        this.validationErrorPhoneNumber = true;
        return false;
      }
    },
    resendOtp: function () {
      var payload = {
        StoreId: parseInt(this.store_id),
        method: "POST",
        DriverPhone: this.tempSettings.DriverPhone,
        Otp: "",
      };
      this.$http
        .post(this.api_url + "Api/V1/Users/ResendOtp/", payload)
        .then((res) => {});
    },
    verifyOtp: function () {
      var payload = {
        StoreId: parseInt(this.store_id),
        method: "POST",
        Otp: this.otp,
        DriverPhone: this.tempSettings.DriverPhone,
      };
      this.$http
        .post(this.api_url + "Api/V1/Users/VerifyOTP/", payload)
        .then((res) => {
          if (res.ok) {
            setTimeout(() => {
              this.currentStep = 5;
              this.isActive = true;
            }, 2000);
          } else {
            this.response.show = true;
            this.response.message = res.body.message;
          }
        });
    },
  },
};
</script>
    
    <style scoped>
.app-content {
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 12px;
}

.column {
  padding: 3px !important;
}

.card-height {
  height: 50rem !important;
}

.title-align {
  text-align: center;
  margin-top: 7rem;
  margin-bottom: 7rem !important;
}

.fields-position {
  margin-bottom: 4rem !important;
}

.fields {
  font-size: 1.25em !important;
  height: 2.5em !important;
}

.button-position {
  margin-top: 10rem;
}

.config-column {
  position: absolute;
  z-index: 9999;
  left: 11rem;
  top: 1rem;
}

.modal-background {
  background-color: rgb(178 178 178 / 86%) !important;
}

.position-skip {
  float: right !important;
  font-size: 13px;
  text-decoration: underline;
}

.ml-4 {
  margin-left: 4px;
}

.title {
  text-align: center;
  font-weight: 600;
  font-size: 1.7rem !important;
  text-align: left;
  margin-bottom: 3rem !important;
}

.modal-card {
  border: 0.0625rem solid #e3e3e3 !important;
  box-shadow: 0 1.3125rem 5.9375rem #0000001a !important;
  border-radius: 0.8125rem !important;
}

.modal-card-body {
  height: 80% !important;
  padding: 40px !important;
  overflow-y: auto !important;
  overflow-x: hidden;
}

.internal-columns-p {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.z3-m-b {
  margin-bottom: 30px !important;
}

.s2-btn-m-t {
  margin-top: 30px !important;
}

span.squareup {
  background: url('../../public/img/squareup.svg') no-repeat top left;
  background-size: contain;
  display: inline-block;
  height: 22px;
  width: 88px;
}

@font-face {
  font-family: "password";
  font-style: normal;
  font-weight: 400;
  src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}

.required-span {
  color: red;
}

input.key {
  font-family: "password";
}

.center {
  text-align: center !important;
}

.otp-p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 1rem;
}

.img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 21%;
}

.mb-2 {
  margin-bottom: 10px;
  text-align: right;
}

.fl-l {
  float: left;
}

.mr-3 {
  margin-right: 1rem;
}

.width-r {
  width: 3rem;
}

.width-within {
  width: 7rem;
}

.opt-top {
  margin-top: 1rem;
}

.driver-h2 {
  margin-bottom: 0px !important;
}

.driver-subtitle {
  margin-top: 0px;
  margin-bottom: 10px;
}

/* input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    
    input[type=number] {
        -moz-appearance: textfield;
    } */
</style>
    