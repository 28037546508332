<template>
    <div class="order-detail-page">
        <request-cancel-modal :open="selectedOrder ? Boolean(selectedOrder.external_delivery_id) : false" :onClose="() => selectedOrder = null"
            :order="selectedOrder" />

        <!-- Outsourced Orders -->
        <div class="columns table-headings is-size-7">
            <div class="column is-1 name-column has-text-left move-text-up">NAME</div>
            <div class="column is-1 order-number-column has-text-left move-text-up">SOURCE</div>
            <div class="column is-1 order-number-column has-text-left move-text-up">SRC ID</div>
            <div class="column is-2 address-column has-text-left move-text-up">ADDRESS</div>
            <div class="column is-1 new-time-column has-text-centered">
                <div class="tooltip" v-show="tooltips[0]">
                Estimated pickup time
                <div class="tooltip-point"></div>
                </div>
                <a class="button is-small has-text-weight-medium" v-on:click="showTool(0)">EST PU</a>
            </div>
            <div class="column is-1 new-time-column has-text-centered">
                <div class="tooltip" v-show="tooltips[1]">
                Estimated delivery time
                <div class="tooltip-point"></div>
                </div>
                <a class="button is-small has-text-weight-medium" v-on:click="showTool(1)">EST DT</a>
            </div>
            <div class="column time-column has-text-centered">
                <div class="tooltip" v-show="tooltips[3]">
                Target delivery time
                <div class="tooltip-point"></div>
                </div>
                <a class="button is-small has-text-weight-medium" v-on:click="showTool(3)">TGT</a>
            </div>
            <div class="column time-column has-text-centered">
                <div class="tooltip" v-show="tooltips[2]">
                Estimated delivery time
                <div class="tooltip-point"></div>
                </div>
                <!-- <a class="button is-small has-text-weight-medium" v-on:click="showTool(2)">EDT</a> -->
            </div>
            <div class="column is-1 name-column has-text-left move-text-up">SERVICE</div>
            <div class="column is-2 name-column has-text-left move-text-up">REF ID</div>
            <div class="column is-1 button-column has-text-centered"></div>
        </div>

        <div class="order-details-area" v-if="outsourcedPickupOrders.length">
            <div class="columns table-rows is-size-7" v-for="o in outsourcedPickupOrders" v-bind:key="o.order_id">

                <div class="column is-1 name-column has-text-left text-bold">
                    {{ getShortName(o.customer_name).toUpperCase() }}
                </div>
                <div class="column is-1 order-number-column has-text-left">
                    <img class="img source-logo" :src="getOrderSourceLogo(o.OrderSource)" />
                </div>
                <div class="column is-1 order-number-column has-text-left">
                    {{ o.source_id ? getShortOrderNumber(o.source_id) : o.order_number }}
                </div>
                <div class="column is-2 address-column has-text-left">{{ (o.address).toUpperCase() }}</div>
                <div class="column is-1 new-time-column has-text-center text-bold">
                    {{  new Date(parseInt(o.pickup_eta.substr(6))).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: true}) }}
                </div>
                <div class="column is-1 new-time-column has-text-center text-bold">
                    {{  new Date(parseInt(o.ExternalEstimatedTimeToDeliver.substr(6))).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: true}) }}
                </div>
                <div class="column time-column has-text-centered">
                    {{ o.target_delivery_time_minutes }}
                    <!-- TGT -->
                </div>
                <!-- <div class="column time-column has-text-centered">
                    {{ - stringTimeDiff(o.entry_time, o.ExternalEstimatedTimeToDeliver) }}
                </div> -->
                <div class="column name-column">
                    {{ o.external_delivery_source.toUpperCase() }}
                </div>
                <div class="column name-column">
                    {{ getShortOrderNumber(o.external_delivery_id) }}
                </div>
                <div class="column is-1 status-column has-text-centered">
                    <a class="button is-small is-primary ready-button" v-bind:class="[
                        { 'is-outlined': !o.is_ready_to_deliver },
                        {
                            'is-loading': orderStatusChanging.indexOf(o.order_id) != -1,
                        },
                        {
                            'is-inverted':
                                !o.is_ready_to_deliver &&
                                selectedOrders.filter((s) => s == o.order_id).length === 1,
                        },
                    ]" v-on:click.stop="$emit('changeOrderStatus', o)">{{
                        o.is_ready_to_deliver ? "Ready" : "Not Ready"
                    }}</a>
                </div>

                <div class="column is-1 status-column has-text-centered tooltip2">
                    <a class="button is-small is-info cancel-btn"
                        v-on:click.stop="() => toggleRequestModal(o)">
                        <i class="fa fa-close"></i>
                    </a>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
var timeFunctions = require("../../js/timeFunctions.js");
var orderFunctions = require("../../js/orderFunctions.js");
var requestPickupModal = require("../../components/add_edit_order/request_pickup_modal").default;
var requestCancelModal = require("../../components/add_edit_order/request_cancel_modal").default;
// const { convertEasternTime } = require("../../js/timeFunctions.js");

module.exports = {
    components: {
        requestPickupModal,
        requestCancelModal,
    },
    data: function () {
        return {
            selectedOrder: null,
            selectedOrderToCancel: null,
            tooltips:[false,false,false,false,false]
        };
    },
    created: function () { },
    computed: {
        outsourcedPickupOrders: function () {
            return this.$store.getters["orders/outsourcedPickupOrders"]
        },
        orderStatusChanging: function () {
            return this.$store.getters["orders/orderStatusChanging"];
        },
        selectedOrders: function () {
            return this.$store.getters["orders/selectedOrders"];
        },
    },
    watch: {},
    methods: {
        showTool(a) {
            for(var i = 0; i < this.tooltips.length; i++){
            this.$set(this.tooltips, i, false);
            }
            this.$set(this.tooltips, a, true);
            setTimeout(() => this.$set(this.tooltips, a, false), 5000)
        },
        elapsedTime: (a) => timeFunctions.elapsedTime(a),
        stringTimeDiff: (a, b) => timeFunctions.stringTimeDiff(a, b),
        shortAddress: (address) => orderFunctions.getShortAddress(address),
        getShortName: (name) => orderFunctions.getShortName(name),
        getOrderSourceLogo: (source) => orderFunctions.getOrderSourceLogo(source),
        getShortOrderNumber: (orderNumber) => orderFunctions.getShortOrderNumber(orderNumber),
        currentTime: () => timeFunctions.currentTime(),
        convertToTime: (a) => timeFunctions.convertToTime(a),
        toggleRequestModal: function (order) {
            this.selectedOrder = order;
        },
    },
    filters: {},
};
</script>

<style scoped>
.table-rows-top-group {
    padding-bottom: 1rem;
}

.source-logo {
    width: 1.4rem;
}

.delivered-item {
    color: #05A13C;
}

.order-details-area {
    height: calc(100vh - 174px);
    overflow-y: auto;
}

.has-text-center {
    text-align: center;
}
</style>
